import { CCC_SA_JUMP_TYPE } from './ccc_config_utils'
// import { webScrollExpose } from './ScrollExpose'

/**
  * @param {*} dom 容器，拥有 j-da-event-box 类名的容器
  * @param {*} config 
  *   @param {*} gaName 
  *   @param {*} saName 
  *   @param {*} scName 神策ici拼接
  *   @param {*} poskey 需要上报的poskey
  *   @param {*} index  组件id
  * 
  *   @param {*} cccPublic  
  *   @param {*} cccCateInfo  
 */
export class CccAnalysis {
  constructor(dom, config = {}) {
    if (!dom) return
    this.container = dom
    this.analysisConfig = config
    this.context = config.context
    // this.scrollExpose = config.scrollExpose || webScrollExpose
    this.cccPublic = config.cccPublic
    this.cccCateInfo = config.cccCateInfo

    this.setContainerAttribute()
  }

  /**
   * 给dom添加属性
   * @param {*} dom 
   * @param {*} key 
   * @param {*} value 
   */
  setDomAttribute(dom, key, value) {
    if(dom.dataset[key]) return
    let attr
    if (/^(data|da|href)/g.test(key)) {
      attr = key
    } else {
      attr = `data-${key}`
    }
    if (dom.getAttribute(attr) && dom.getAttribute(attr) === value) return
    dom.setAttribute(attr, value)
  }
  

  /**
   * 父容器添加属性
   */
  setContainerAttribute() {
    const { poskey = '', index: comIndex = 1, gaName = '', saName = '' } = this.analysisConfig
    const containerAttrConfig = {
      poskey,
      'com-index': comIndex,
      'imp-list': gaName,
      'sa-list': saName
    }
    for (const key in containerAttrConfig) {
      const value = containerAttrConfig[key]
      if (!value) continue
      this.setDomAttribute(this.container, key, value)
    }
  }
  /**
  ** @description {*} - return params
  ** @param {*} data - ccc配置
  ** @param {*} hotZoneData - ccc热区配置
  ** @param {*} aodId - 精投id
  ** @param {*} banners - banner相关dom
  ** @param {*} hotZones - hotZones相关dom
  ** @param {*} viewalls - banner相关dom
  ** @param {*} items - 商品相关dom
  */
  getBaseInfo(propData) {
    if (!this.container) return
    if (!propData) return
    const data = propData.content && propData.content.props && propData.content.props.items
    if (!data || !data.length) return
    const hotZoneData = propData.content && propData.content.hotZoneData
    const aodId = propData.content && propData.content.props && propData.content.props.style && propData.content.props.style.aod_id || '0'
    let banners
    if (this.container.querySelectorAll(`.j-analysis-link`) && this.container.querySelectorAll(`.j-analysis-link`).length) {
      banners = this.container.querySelectorAll(`.j-analysis-link`)
    } else if (this.context.$refs['j-analysis-link'] && this.context.$refs['j-analysis-link'].length) {
      banners = this.context.$refs['j-analysis-link']
    }
    const hotZones = this.container.querySelectorAll(`.j-analysis-hot`) || []
    const viewalls = this.container.querySelectorAll(`.j-analysis-view-all`) || []
    const items = this?.context?.$refs['j-analysis-item']
    return {
      data,
      hotZoneData,
      aodId,
      banners,
      hotZones,
      viewalls,
      items,
      operationName: propData.oper_name,          // 运营位名称
      operationComponentName: propData.content?.name,  // 组件名称
    }
  }
  getDomSpm({ dom, cccItem = {}, customJumpType = null, comId = '', index = 0, type = 'banner' } = {}) {
    const jumpType = (cccItem.hrefType === 'special') ? cccItem.hrefType2 : cccItem.hrefType
    let jumpTypeId = ''
    let spm = ''
    if (type === 'banner') {
      jumpTypeId = cccItem.hrefTypeId || CCC_SA_JUMP_TYPE[jumpType]
    } else if (type === 'hotZone') {
      jumpTypeId = cccItem.props.hrefTypeId || CCC_SA_JUMP_TYPE[jumpType] || 32
    } else if (type === 'viewall') {
      jumpTypeId = cccItem.hrefTypeId2 || CCC_SA_JUMP_TYPE[customJumpType]
    } else if (type === 'goodsItem') {
      jumpTypeId = cccItem.hrefTypeId || CCC_SA_JUMP_TYPE[cccItem.hrefType || customJumpType]
    }
    // todo 坑位取值优先级 手动绑定 > ccc配置 > 组件索引
    // todo 样式取值优先级 ccc配置 > 前端配置表
    // SPM生成逻辑，由：页面ID`tabID`场景ID_场景类型`模板ID`区块ID`运营位ID`运营位位置ID`样式ID`人群ID`坑位ID`热区ID`层级ID`跳转类型ID `组件ID
    if (type !== 'goodsItem') {
      spm = `${dom.dataset.positionIndex || (type === 'hotZone' ? cccItem.props.position : cccItem.position) || index + 1}\`${type === 'hotZone' ? cccItem.id : 1}\`0\`${jumpTypeId}\`${comId}`
    } else {
      spm = `${dom.dataset.positionIndex || cccItem.position || 0}\`0\`0\`${jumpTypeId}\`${comId}`
    }
    return spm
  }
  /**
  * 获取对应上报属性
  * @param {*} dom - 对应dom
  * @param {*} cccData - 对应ccc配置
  * @param {*} cccHotData - 对应ccc热区配置
  * @param {*} products - 商品数据
  * @param {*} comId - 组件id
  * @param {*} aodId - 精投id
  * @param {*} type - 设置类型
  * @param {*} operation - 运营配置的相关信息
  */
  getDomBaseAttr( 
    { 
      dom = {}, 
      cccData = [], 
      cccHotData = [],
      products = [],
      type = 'banner', 
      aodId = 0, 
      index = 0,
      comId = '',
      operation
    } = {} ) {
    const { jumpType } = this.analysisConfig
    let cccItem = type === 'hotZone' ? cccHotData[index] || {} : cccData[index] || cccData[0]
    const spm = this.getDomSpm({ dom, cccItem, customJumpType: jumpType, comId, index, type })
    const hotZoneName = type === 'hotZone' ? cccItem.name : 0 
    const hotZoneId = type === 'hotZone' ? cccItem.id : 0
    
    // 针对热区取相应配置 
    cccItem = type === 'hotZone' ? cccItem.props : cccItem
      
    if (type === 'hotZone') {
      cccItem.hotZoneId = hotZoneId
      cccItem.hotZoneName = hotZoneName
    }
    let href = ''
    const gaIndex = dom.dataset.positionIndex || cccItem.position || (type !== 'viewall' ? index + 1 : 0)
    if (this.cccPublic && this.cccCateInfo) {
      href = this.cccPublic.getCccLink({
        shouldUserPath: this.analysisConfig.shouldUserPath || false,
        item: type !== 'goodsItem' ? cccItem : { ...cccItem, scHrefTag: 'hrefTypeTag2' },
        cccCateInfo: this.cccCateInfo,
        gaIndex: gaIndex - 1,
        scIndex: gaIndex - 1,
        isHot: type === 'hotZone'
      })
    }

    const commonConfig = {
      'scene-name': operation.sceneName,
      'operation-name': operation.operationName,
      'operation-component-name': operation.operationComponentName,
    }

    let domAttrConfig
    if (type !== 'goodsItem') {
      domAttrConfig = {
        'href': href,
        'da-event-click': '2-10-1',
        spm,
        'aod-id': aodId,
        'data-href-tag': type !== 'viewall' ? cccItem.hrefTypeTag : cccItem.hrefTypeTag2,
        'data-href-target': cccItem.hrefTarget,
        'data-href-type': cccItem.hrefType,
        'data-href-type-two': cccItem.hrefType2,
        'position-index': gaIndex,
        ...commonConfig
      }
      // 热区名称
      if (type === 'hotZone') {
        domAttrConfig['hot-zone-name'] = hotZoneName
      }
    } else {
      const itemData = products[index] || {}
      let gaTarget = `CS=0`
      if (cccItem.hrefType) {
        gaTarget = `CS=${cccItem.hrefType === 'sku' ? 'SKU' : `1_${cccItem.id || 0}`}`
      }
      if (dom.dataset.gaTarget) {
        gaTarget = dom.dataset.gaTarget
      }
      domAttrConfig = {
        'da-event-click': '2-10-3',
        'goods_id': itemData.goods_id,
        'spu': itemData.productRelationID || itemData.goods_sn,
        'sku': itemData.goods_sn,
        spm,
        'ga-target': gaTarget,
        'position-index': gaIndex,
        'cat_id': itemData.cat_id,
        'price': itemData.salePrice && itemData.salePrice.usdAmount,
        'us-price': itemData.salePrice && itemData.salePrice.usdAmount,
        'us-origin-price': itemData.retailPrice && itemData.retailPrice.usdAmount || 0,
        'brand': itemData?.brand_badge_new?.name || itemData.brand || '',
        'href-target': cccItem?.hrefTarget,
        ...commonConfig
      }
    }
    return domAttrConfig
  }
  /*
  ** 设置对应上报属性
  */
  setDomBaseAttr({ doms = [], data, hotZoneData, products, aodId, useDefaultTabIndex, comId, type, operation = {} } = {}) {
    doms.forEach((dom, index) => {
      const attrConfig = this.getDomBaseAttr(
        { 
          dom, 
          cccData: data, 
          cccHotData: hotZoneData, 
          products,
          useDefaultTabIndex, 
          type,
          aodId, 
          index: index, 
          comId,
          operation
        }
      )
      for (const key in attrConfig) {
        const value = attrConfig[key]
        if (!value) continue
        this.setDomAttribute(dom, key, value)
      }
    })
  }
  /** 
   * dom添加属性
   * @param {*} propData ccc外层配置
   * @param {*} products 商品数据
   */
  setDomsAttribute({ propData, products = [], useDefaultTabIndex = true, sceneName } = {}) {
    if (!this.getBaseInfo(propData)) {
      return
    }
    this.propData = propData
    const { banners, hotZones, viewalls, items, data, hotZoneData, aodId, operationName, operationComponentName } = this.getBaseInfo(propData)
    const list = []
    if (banners && banners.length) list.push({ arr: banners, type: 'banner' })
    if (hotZones && hotZones.length) list.push({ arr: hotZones, type: 'hotZone' })
    if (viewalls && viewalls.length) list.push({ arr: viewalls, type: 'viewall' })
    if (items && items.length) list.push({ arr: items, type: 'goodsItem' })
    
    list.forEach(item => {
      this.setDomBaseAttr(
        { 
          doms: item.arr,
          type: item.type, 
          data, 
          hotZones, 
          hotZoneData,
          products: products || [], 
          aodId, 
          useDefaultTabIndex,
          comId: propData.content.id,
          // 运营配置相关
          operation: {
            sceneName,                // 场景名称
            operationName,            // 运营位名称
            operationComponentName    // 组件名称
          }
        }
      )
    })
  }
}
