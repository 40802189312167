<template>
  <!-- 搜索结果为空展示 -->
  <!-- 分为全局搜索空  店铺搜索空 -->
  <div
    class="productList-empty fsp-element"
    :class="isStoreNoResultNewStyle && 'store-empty'"
  >
    <template v-if="IS_RW">
      <sui_img_nodata_default_75px
        v-if="IS_RW"
        color="#fc4070"
        size="75px"
      />
      <p class="productList-empty__text">
        {{ language.SHEIN_KEY_PC_16307 }}
      </p>
    </template>

    <!-- 店铺搜索 -->
    <template v-else-if="pageType === 'storeSearch'">
      <!-- 新店铺搜索空态，和pwa不同，pc的比较清晰，所以放在这里处理。参见：wiki pageId=1274994420 -->
      <template v-if="isStoreNoResultNewStyle">
        <div class="new-empty__text">
          <div class="decorate-line"></div>
          <div
            class="text-content"
            v-html="storeNewText"
          ></div>
          <div class="decorate-line"></div>
        </div>
      </template>
      <!-- 之前的 -->
      <template v-else>
        <img
          :src="'/she_dist/images/list_no_result_store_icon-0ee53dc85b.png'"
        />

        <p
          class="productList-empty__text font-gary font-12"
          v-html="storeText"
        ></p>

        <div class="tip-wrap">
          <p class="tip-wrap__title font-12">
            {{ language.SHEIN_KEY_PC_25467 }}
          </p>
          <p class="tip-wrap__text font-12">
            {{ language.SHEIN_KEY_PC_25465 }}
          </p>
          <p class="tip-wrap__text font-12">
            {{ language.SHEIN_KEY_PC_25466 }}
          </p>
        </div>
        <a
          class="link-back"
          href="javascript:history.back(-1)"
        >
          {{ language.SHEIN_KEY_PC_26549 }}>
        </a>
      </template>
    </template>

    <!-- 全站搜索 -->
    <template v-else-if="pageType === 'all'">
      <img :src="'/she_dist/images/list_no_result_icon-7d7e7f2958.png'" />
      <p class="productList-empty__text">
        {{ language.SHEIN_KEY_PC_16307 }}
      </p>
      <SButton
        v-if="showBack"
        class="productList-empty__button"
        :type="['primary', 'H36PX']"
        @click="onclickBtn"
      >
        {{ language.SHEIN_KEY_PC_31567 }}
      </SButton>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { template, parseQueryString } from '@shein/common-function'
import { sui_img_nodata_default_75px } from '@shein-aidc/icon-vue3'
import { SButton } from '@shein-aidc/sui-button'
import mixin from 'public/src/pages/product_list_v2/components/filter/mixins/index.js'
import { hasFilterBarQueryParams } from 'public/src/pages/product_list_v2/utils/index.js'

export default {
  name: 'ResultEmpty',
  components: {
    sui_img_nodata_default_75px,
    SButton
  },
  mixins: [mixin],
  data() {
    return {
      pageType: 'all'
    }
  },
  computed: {
    ...mapState(['language', 'IS_RW', 'retainQueryObj']),
    ...mapGetters(['catInfo', 'storeData', 'listAbtResult']),
    // 参见：wiki pageId=1274994420
    isStoreNoResultNewStyle() {
      return (
        ['new1', 'new2'].includes(
          this.listAbtResult.storesearchefficiencyoptimize?.p
            ?.storesearchefficiencyoptimize
        ) && !this.IS_RW
      )
    },
    storeNewText() {
      return (
        template(
          `<b style="color:#000;font-weight: 800;">${this.catInfo.keywords}</b>`,
          this.language.SHEIN_KEY_PC_29400
        ) ||
        `No results were found for "<b style="color:#000;font-weight: 800;">${this.catInfo.keywords}</b>" in this store.`
      )
    },
    storeText() {
      return this.language.SHEIN_KEY_PC_25468?.replace(
        '{0}',
        `<b style="color:#000;">${this.catInfo.keywords}</b>`
      )
    },
    showBack() {
      return hasFilterBarQueryParams(this.catInfo)
    }
  },
  created() {
    const { type, sub_type, keywords } = this.catInfo

    // 默认展示全站搜索空 文案
    if (type === 'store' && sub_type === 'search') {
      this.pageType = 'storeSearch'
      this.keywords = keywords
    }
  },
  methods: {
    ...mapMutations(['jumpUrl']),
    onclickBtn() {
      const queryObj = parseQueryString(location.search)
      Object.keys(queryObj).forEach(key => {
        if (!this.retainQueryObj[key]) {
          queryObj[key] = undefined
        }
      })

      this.handleJumpUrl(queryObj)
    },
    // 筛选后跳转
    handleJumpUrl(queryObj) {
      delete queryObj.page
      this.jumpUrl({ queryObj })
      location.reload()
    }
  }
}
</script>

<style lang="less" scoped>
.productList-empty {
  margin: 0 auto;
  padding: 150px 0;
  font-size: 14px;
  text-align: center;
  /* rw:begin */
  padding-top: 44px;

  img {
    padding-bottom: 20px;
  }

  &__text {
    line-height: 20px;
    /* rw:begin */
    padding-top: 12px;

    &.font-gary {
      color: #767676;
    }
  }

  .font-12 {
    font-size: 12px;
  }
  &__button {
    margin-top: 20px;
  }
}
.store-empty {
  font-size: 14px;
  text-align: center;
  margin: 0 auto;
  background: #f9f9f9;
  padding: 35px;
  margin-top: 24px;
  .new-empty__text {
    color: var(---sui_color_gray_dark3, #767676);
    position: relative;
    font-family: 'SF UI Display';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    .decorate-line {
      width: 32px;
      height: 1px;
      min-width: 32px;
      background: #767676;
    }
    .text-content {
      margin: 0 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
  }
}

.tip-wrap {
  padding-top: 22px;
  padding-bottom: 20px;

  display: inline-block;
  margin: 0 auto;

  text-align: left;
  font-size: 12px;
  color: #999;

  &__title {
    line-height: 14px;
  }

  &__text {
    padding-top: 8px;

    line-height: 14px;

    &::before {
      margin-right: 5px;
      display: inline-block;

      content: '';

      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #999;
    }
  }
}
.link-back {
  transform: translateX(-6px);
  margin: 0 auto;
  display: block;

  width: 90px;
  font-size: 12px;
  color: var(--sui-color-link, #2d68a8);
}
</style>
