import { buildCccGaParams, getSaCccSourceFrom, buildCccAbtInfo, buildCccSaParams, CCC_STYLE_ID } from 'public/src/pages/common/analysis/ccc_config_utils.js'
import URL from 'url'
import { get } from 'lodash'
import schttp from 'public/src/services/schttp'
import { transformPromodiscountUrl } from 'public/src/pages/common/biz_helper/shein_picks'
import { replaceHrefSpecChar, parseQueryString } from '@shein/common-function'
import { userPath, getGoodsUrl } from 'public/src/pages/common/utils/index.js'

/**
 * CCC 链接初始化 获取特殊类型的最新链接
 * @description                       SSR 请放在 beforeMouted 或 mouted 阶段，确保 window 对象存在
 * @param {Object} cccData            传入 CCC 数据
 * @param {Object} cccOldCateInfo     多次调用时，传入旧数据进行合并
 */
export function getCccCateInfo ({ cccData = [], cccOldCateInfo = {}, isBreadCrumb = false }) {
  let cccCateInfo = {}
  const ids = {
    real: [],
    itemPicking: []
  }
  let skus = []

  // 1. 获取 ids 和 skus 参数
  getRequestParams({ isBreadCrumb })

  // 2. 去重
  setRequestParamsUnique()

  // 3. 请求接口获取链接数据
  const categoryInfo = new Promise((resolve) => {
    if (ids.real.length || ids.itemPicking.length) {
      const result = {}
      schttp({
        method: 'GET',
        dataType: 'json',
        url: '/c-index/getCateInfo',
        params: {
          ids
        }
      }).then(data => {
        if (data && Object.keys(data).length) {
          for (const key in data) {
            // 选品数据在 itemPickIds 对象中
            if (key === 'itemPickIds' && data[key] && Object.keys(data[key]).length) {
              for (const subKey in data[key]) {
                result[subKey] = getUrl(subKey, data[key][subKey])
              }
            } else {
              result[key] = getUrl(key, data[key])
            }
          }
        }
        resolve(result)
      }).catch(() => {
        resolve({})
      })
    } else {
      resolve({})
    }
  })

  const skuInfo = new Promise((resolve) => {
    if (skus.length) {
      schttp({
        method: 'POST',
        dataType: 'json',
        traditional: true,
        url: '/api/ccc/c-index/getItemBySKU/query',
        data: {
          sku: skus
        },
      }).then(data => {
        if (data && Number(data.code) === 0) {
          const result = {}
          const products = (data.info && data.info.products) || []

          for (const item of products) {
            const key = `sku_${item.goods_sn}`
            const url = getGoodsUrl(item.goods_url_name, item.goods_id, item.cat_id)

            result[key] = url
          }
          resolve(result)
        } else {
          resolve({})
        }
      }).catch(() => {
        resolve({})
      })
    } else {
      resolve({})
    }
  })

  return Promise.all([categoryInfo, skuInfo]).then(res => {
    for (const info of res) {
      cccCateInfo = { ...cccCateInfo, ...info }
    }

    return { ...cccOldCateInfo, ...cccCateInfo }
  }).catch(() => {
    return cccOldCateInfo
  })

  /**
   * 循环过滤出请求参数
   */
  function getRequestParams ({ isBreadCrumb }) {
    if (isBreadCrumb) { /* 针对列表页 CCC 面包屑 */
      for (const item of cccData) {
        if (item.hrefTarget) filterType(item.hrefType, item.hrefTarget)
      }
    } else if (cccData.length) {
      for (const content of cccData) {
        for (const operation of get(content, 'operations', [])) {
          const operationContent = get(operation, 'content', {})
          const operationContentItems = get(operationContent, 'props.items', [])
          const operationContentHotZones = get(operationContent, 'hotZoneData', [])
          const operationCarouselItems = get(operationContentItems, '[0].carouselItems', [])
          const operationImageItems = get(operationContentItems, '[0].imageItems', [])

          switch (operationContent.type) {
            case 'IMAGE_COMPONENT':
            case 'IMAGE_WORD_COMPONENT':
            case 'LIST_BANNER_COMPONENT':
            case 'COUNT_DOWN_COMPONENT':
            case 'APP_ICON_COMPONENT':
              if (operationContentItems.length) {
                for (const item of operationContentItems) {
                  if (item.hrefTarget) filterType(item.hrefType, item.hrefTarget)
                }
              }

              if (operationContentHotZones.length) {
                for (const hotzone of operationContentHotZones) {
                  const hotzoneProps = hotzone.props

                  if (hotzoneProps.hrefTarget) filterType(hotzoneProps.hrefType, hotzoneProps.hrefTarget)
                }
              }

              break
            case 'IMAGE_GROUP_COMPONENET':
              if (operationCarouselItems.length) {
                for (const item of operationCarouselItems) {
                  if (item.hrefTarget) filterType(item.hrefType, item.hrefTarget)
                }
              }

              if (operationImageItems.length) {
                for (const item of operationImageItems) {
                  if (item.hrefTarget) filterType(item.hrefType, item.hrefTarget)
                }
              }

              break
            case 'ITEM_CAROUSEL_COMPONENT':
            case 'IMAGE_CAROUSEL_COMPONENT':
            case 'TOP_BANNER_COMPONENT':
            case 'ORDER_TRACK_BANNER_COMPONENT':
            case 'RANK_BANNER_COMPONENT':
            case 'LIST_FLOW_BANNER_COMPONENT':
            case 'JUMP_CARD_COMPONENT':
              if (operationContentItems.length) {
                for (const item of operationContentItems) {
                  if (item.hrefTarget) filterType(item.hrefType, item.hrefTarget)
                }
              }

              break
            case 'LIST_PAGE_IMG_COMPONENT':
              if (operationContentItems.length) {
                for (const item of operationContentItems) {
                  if (item.hrefTarget) filterType(item.hrefType, item.hrefTarget)

                  for (const link of item.links) {
                    for (const subLink of link.subLinks) {
                      if (subLink.hrefTarget) filterType(subLink.hrefType, subLink.hrefTarget)
                    }
                  }
                }
              }

              break
          }
        }
      }
    }
  }

  /**
   * 链接类型整合
   * @param {String} type     链接类型
   * @param {String} value    链接内容
   */
  function filterType (type, value) {
    switch (type) {
      case 'real':
      case 'realOne':
      case 'realTwo':
        ids.real.push(value)
        break
      case 'sku':
        skus.push(value)
        break
      case 'itemPicking':
        ids.itemPicking.push(value)
        break
    }
  }

  /**
   * 将重复的请求参数去除
   */
  function setRequestParamsUnique () {
    for (const key in ids) {
      ids[key] = unique(ids[key])
    }

    skus = unique(skus)
  }
}

/**
 * 初始化链接所需的所有参数
 * @param {Object}  context           父级数据
 * @param {Object}  propData          当前模块的数据
 * @param {Number}  index             当前的楼层位置
 * @param {String}  navName           首页当前底部导航名称
 * @param {String}  sceneName         首页当前频道名称
 * @param {String}  tagId             登陆 50000 未登陆 50001
 * @param {Array}   abtGroups         ABT 数据
 * @param {Number}  styleId           首页 Video 模块使用
 */
export class CccInit {
  constructor ({
    context = {},
    propData = {},
    index = 0,
    navName = '',
    sceneName = '',
    tagId = '',
    abtGroups = [],
    styleId = '',
    placeholder = null
  }) {
    this.context = context
    this.propData = propData
    this.index = index
    this.navName = navName
    this.sceneName = sceneName
    this.tagId = tagId
    this.abtGroups = abtGroups
    this.styleId = styleId
    this.placeholder = placeholder

    this.saName = ''
    this.gaName = ''

    this.initParams()
  }

  /**
   * 初始化链接参数
   */
  initParams () {
    const { name } = buildCccGaParams({
      propData: this.propData,
      sceneName: this.sceneName || 0,
      tagId: this.tagId,
      abtInfo: buildCccAbtInfo({ abtList: this.abtGroups, separator: '-' }),
      placeholder: this.placeholder || null
    })

    this.saName = buildCccSaParams({
      contextContent: this.context,
      propData: this.propData,
      pageInfo: window.SaPageInfo,
      index: this.index,
      tabSceneId: this.tabSceneId,
      comStyleIndex: this.propData?.content?.props?.style?.styleId || this.styleId || CCC_STYLE_ID[this.propData?.content?.props?.style?.type] || 1,
      tagId: this.tagId
    })
    this.gaName = name

    this.scName = ''
  }

  /**
   * 获取 CCC 链接（包含参数）
   * @param {Object}  item                   当前 item
   * @param {Object}  cccCateInfo            传入 cccCateInfo
   * @param {Number}  gaIndex                Ga 埋点索引
   * @param {Number}  scIndex                神策埋点索引
   * @param {Number}  sliderIndex            轮播分屏索引，楼层_第几屏_第几个（特殊埋点格式）
   * @param {Boolean} shouldUserPath         是否需要用户路径参数
   */
  getCccLink ({ item = {}, cccCateInfo = {}, gaIndex = 0, isHot = false, scIndex = 0, sliderIndex = 0, shouldUserPath = false, useDefaultTabIndex = true }) {
    let url = setCccLink({ item, cccCateInfo })

    if (url === 'javascript:;') return url

    return this.setUrlParams({ item, url, gaIndex, isHot, scIndex, sliderIndex, shouldUserPath, useDefaultTabIndex })
  }
  // 店铺列表
  getStoreLink ({ item = {} }) {
    const itemHrefTarget = item.hrefTarget
    return new Promise(resolve => {
      schttp({
        url: `/api/store/matchStoreJumpUrl/get?storeCode=${itemHrefTarget}`, 
      }).then(res => {
        const links = res?.links || {}
        const link = links[itemHrefTarget] || ''
        resolve(link)
      })
    })
  }
  /**
   * 设置链接参数
   * @param {Object} item       当前 CCC 数据
   * @param {String} url        链接
   * @param {Number} gaIndex    位置
   */
  setUrlParams ({ item, url, gaIndex, isHot = false, scIndex, shouldUserPath, }) {
    const ici = `ici=${this.gaName.replace('{-}', `PS=${isHot ? 'HZ-' : ''}${this.index}-${gaIndex + 1}`)}`
    const adp = item.adp ? `&adp=${item.adp}` : ''

    // ici、adp 参数
    url = `${url}${ url.indexOf('?') > -1 ? '&' : '?'}${ici}${adp}`
    // CCC配置的mallCode
    if(item.mallCodes){
      url = url + `&mallCode=${item.mallCodes}`
    }
    // 配置需开启的参数类型
    const paramsConfig = {
      discountListParam: ['discountList'],
      activityParam: ['activity'],
      userPathParam: ['real', 'realOne', 'realTwo', 'sku', 'itemPicking', 'dailyNew', 'discountList'],
      categoryParam: ['dailyNew', 'flashSale', 'lightningDeal']
    }

    // Discount 参数
    if (paramsConfig.discountListParam.includes(item.hrefType === 'special' ? item.hrefType2 : item.hrefType)) url = transformPromodiscountUrl(getDiscountListParam(url, item))
    // Activity 参数
    if (paramsConfig.activityParam.includes(item.hrefType)) url = activityUrlAddQuery(url, item)
    // Category 参数
    if (paramsConfig.categoryParam.includes(item.hrefType === 'special' ? item.hrefType2 : item.hrefType)) url = getCategoryParam(url, item.categoryType, item.categoryList)
    // 用户路径参数
    if (shouldUserPath && paramsConfig.userPathParam.includes(item.hrefType === 'special' ? item.hrefType2 : item.hrefType)) url = this.addUserPathParam(url, item)
    // Category 页默认选中 Tab
    if (item.hrefType === 'category') url += `&channelId=${item.hrefTarget}`
    // bi埋点链接参数添加
    const { url_params } = getSaCccSourceFrom({ 
      sceneName: this.sceneName, 
      operationName: this.propData.oper_name,
      operationComponentName: this.propData?.content?.name,
      comIndex: this.index,
      positionIndex: scIndex + 1,
      hotZone: isHot ? { name: item.hotZoneName } : null,
      item
    })
    if (url_params) url += `&${url_params}`

    return url
  }
  /**
   * 列表页链接拼接用户路径参数
   * @author Loading
   * @param {String} url      跳转链接
   * @param {Object} item     当前 CCC 数据
   */
  addUserPathParam (url, item) {
    if (userPath && Object.keys(userPath).length) {
      if (!userPath.getTitle(url)) return url

      url += `&srctype=homepage&userpath=${replaceHrefSpecChar({ href: `${this.navName}>${this.sceneName}>${item.title || item.content || userPath.getTitle(url)}` })}`
    }

    return url
  }
}

/**
 * 获取 CCC 链接（不包含参数）
 * @param {Object}  item                   当前 item
 */
export function getLiteCccLink ({ item = {}, cccCateInfo = {} }) {
  return setCccLink({ item, cccCateInfo })
}

/**
 * CCC 链接拼接
 * @param {Object} item                     当前 CCC 数据
 * @param {Object} cccCateInfo              特殊链接数据
 */
function setCccLink ({ item = {}, cccCateInfo = {} }) {
  let splitHrefType

  let returnValue = 'javascript:;'
  const itemHrefType = item.hrefType
  const itemHrefType2 = item.hrefType2
  const itemHrefTarget = item.hrefTarget

  switch (itemHrefType) {
    // 不跳转
    case 'noJump':
      returnValue = 'javascript:;'
      break
    // Web 链接
    case 'webLink':
      returnValue = itemHrefTarget
      break
    // 活动链接
    case 'game':
      returnValue = item.gameType ? `/${item.gameType}/${itemHrefTarget}` : 'javascript:;'
      break
    case 'activity':
      returnValue = `/campaign/${itemHrefTarget}`
      break
      // 旧ccc跳新专题
    case 'activityNew':
      returnValue = `/campaigns/${itemHrefTarget}`
      break
      // 分类链接
    case 'real':
    case 'realOne':
    case 'realTwo':
    case 'sku':
    case 'itemPicking':
      splitHrefType = itemHrefType.split('_')[0]

      if (splitHrefType === 'realOne' || splitHrefType === 'realTwo') splitHrefType = 'real' // realOne、realTwo 属于 real 类型

      returnValue = cccCateInfo[(`${splitHrefType}_${itemHrefTarget}`)] || item.catUrl || item.relativeUrl || 'javascript:;'

      break
    // 特殊分类链接
    case 'special':
      switch (itemHrefType2) {
        case 'dailyNew':
          returnValue = '/daily-new.html'
          break
        case 'discountList':
          returnValue = transformPromodiscountUrl('/shein-picks.html')
          break
        case 'flashSale':
        case 'lightningDeal':
          returnValue = '/flash-sale.html'
      }
      break
    // Daily New 链接
    case 'dailyNew':
    case 'whatsnew':
      returnValue = '/daily-new.html'
      break
    // Discount 链接
    case 'discountList':
      returnValue = transformPromodiscountUrl('/shein-picks.html')
      break
    // 闪购链接
    case 'flashSale':
    case 'lightningDeal':
      returnValue = '/flash-sale.html'
      break
    // 问卷链接
    case 'survey':
      returnValue = `/survey/${itemHrefTarget}`
      break
    // 试用中心链接
    case 'trial':
      returnValue = '/free-trial-center/index'
      break
    // Look Book 链接
    case 'lookBook':
      returnValue = `/shop-multiple-sets-${itemHrefTarget}.html`
      break
    // 礼品卡
    case 'giftCard':
      returnValue = '/giftcard.html'
      break
    case 'article':
      {
        const linkName = item.selectTypeName || 'article'
  
        returnValue = `/${linkName}-a-${itemHrefTarget}.html`
      }
      break
    // 优惠券中心
    case 'couponCenter':
      returnValue = '/user/coupon'
      break
    // 付费会员落地页
    case 'prime':
      returnValue = '/user/prime'
      break
      // 设计师详情页
    case 'sheinxDesignerInformation':
      returnValue = `/designer/${itemHrefTarget}`
      break
      // 设计师列表页
    case 'sheinxDesignerList':
      returnValue = '/designer_list'
      break
  }

  returnValue = replaceHrefSpecChar({ href: returnValue })

  return returnValue
}

/**
 * real、itemPicking 特殊类型链接生成
 * @param {String} key        键
 * @param {Object} detail     值
 */
function getUrl (key, detail) {
  const type = key.split('_')[0]

  if (Object.prototype.toString.call(detail) === '[object Object]') {
    switch (type) {
      case 'real':
        return `/${detail.cat_url_name && detail.cat_url_name.replace(/%/g, '')}-c-${detail.cat_id}.html`
      case 'itemPicking':
        return `${detail.sc_url ? detail.sc_url : [detail.select_type_name, `${detail.select_name}-sc-${detail.sc_url_id}.html`].join('/')}`
    }
  } else {
    return 'javascript:;'
  }
}

function getDiscountListParam (url, item) {
  return `${url}${item.hrefTarget ? `&hrefTarget=${item.hrefTarget}` : ''}`
}

/**
 * 活动页链接拼接 query 参数
 * @author songwei
 * @description wiki.pageId=283541755
 * @param {String} url                      跳转链接
 * @param {String} opt.hrefType             类型，activity=专题
 * @param {Object} opt.additionalConfig     待拼接的参数对象
 * @return {String}                         拼接完成的跳转链接
 */
function activityUrlAddQuery (url = '', opt = {}) {
  if (!opt.hrefType || opt.hrefType !== 'activity') {
    return url
  }

  opt.additionalConfig = opt.additionalConfig || {}

  const urlObj = URL.parse(url) // eslint-disable-line
  const query = parseQueryString(urlObj.query)
  const anchor = opt.additionalConfig.anchor || ''
  const immersive = Number(opt.additionalConfig.immersive) || 0

  if (immersive) {
    query.type = 'immersive'
  }

  if (anchor) {
    query.anchor = anchor
  }

  urlObj.search = ''
  urlObj.query = query

  return URL.format(urlObj)
}

/**
 * 获取 Category Param
 * @param {String} categoryType
 * @param {Array} categoryList
 */
function getCategoryParam (url, categoryType = '', categoryList = []) {
  const { IS_RW } = window.gbCommonInfo
  const catIdsArray = []

  for (const item of categoryList) {
    if (Number(categoryType) === 2 && item.val) {
      if (IS_RW) {
        catIdsArray.push(item['romwe_cat_id'])
      } else {
        catIdsArray.push(item['shein_cat_id'])
      }
    }
  }

  url += catIdsArray.length ? `&cat_ids=${decodeURIComponent(catIdsArray.join(','))}` : ''

  return url
}

/**
 * 数组去重
 * @param {Array} arr 传入数组
 */
function unique (arr) {
  const res = []

  for (const item of arr) {
    if (res.indexOf(item) === -1) {
      res.push(item)
    }
  }

  return res
}
