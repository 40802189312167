/**
 * 白名单内的接口及js会监控其耗时及大小
 */

/** 接口白名单 */
export const apiWhiteList = []

/** js白名单 */
export const jsWhiteList = []

/** html白名单[实验性用于大盘html文件体积,数据回收后会删除] origin用于归类, 先仅收集几个90差距大的场景用于分析 */
export const htmlWhiteList = {
  pageDetail: {
    names: ['page_goods_detail'],
    origin: 7,
  },
  pageList: {
    names: ['page_real_class', 'page_select_class'],
    origin: 7,
  },
  pageActivity: {
    names: ['page_activity_factory'],
    origin: 8,
  },
  pageStore: {
    names: ['page_store'],
    origin: 8,
  }
}
