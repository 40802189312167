import { getCookie } from '@shein/common-function'
import { apiWhiteList, jsWhiteList, htmlWhiteList } from './const.js'

// 用于暂存打点时间戳(clientSDK还没有初始化时)
if (typeof window !== 'undefined') {
  window.CLIENT_SDK = {
    DateTimeMarkList: []
  }
}

function markPoint(...agrs) {
  try {
    // Node 环境下不执行
    if (typeof window === 'undefined') {
      return
    }

    // 无性能对象不执行
    if (!window.performance) {
      return
    }

    // 默认值
    let metric = {
      eventName: '',
      resource: '',
      tag: '',
      measureTo: '',
      measureFrom: '',
      timestamp: Date.now()
    }
    
    // 兼容老的打点方式 agrs[0]
    if (typeof agrs[0] === 'string') {
      metric.eventName = agrs[0]
      // TODO MYTEST 统一补充 toNextPageClick 到 routeChangeStart 来生成打点阶段
      if (metric.eventName === 'toNextPageClick') {
        metric.measureTo = 'routeChangeStart'
      }
    } else if (typeof agrs[0] === 'object') {
      const markData = agrs[0] || {}
      metric = {
        ...metric,
        ...markData,
      }
    }

    // 数据检查
    if (!metric.eventName) {
      return
    }

    const sdkIsReady = typeof window?.clientAnalytics?.PerformanceTargetCollectInner?.addMetrics === 'function'
    const engineType = getMarkEngineType()
    let markName = `__SHEIN__public__${metric.eventName}`
    if (metric.tag) {
      markName = `${markName}__${metric.tag}`
    }
    if (!sdkIsReady) {
      // 本地存储 + 是否需要 performance.mark
      window.CLIENT_SDK.DateTimeMarkList.push(metric)
      if (engineType === 'performanceMark') {
        window.performance.mark(markName)
      }
      return
    }
  
    window.clientAnalytics.PerformanceTargetCollectInner.addMetrics(metric)
    if (engineType === 'performanceMark') {
      window.performance.mark(markName)
    }
    return
  } catch (err) {
    console.error(err)
  }
}

// 获取打点引擎类型
let markEngineType
// 可选类型: performanceMark, dateNow, testDateNow
function getMarkEngineType() {
  if (markEngineType !== undefined) {
    return markEngineType
  }
  const type = getCookie('performanceTest') || ''
  const supportVueDebug = getCookie('vueDebug') || ''
  const isLaboratory = window.localStorage.getItem(`${performanceStorageKey}_IS_LABORATORY`)
  if (type === '1' || isLaboratory === '1') {
    markEngineType = 'testDateNow'
  } else if (supportVueDebug === '1') {
    markEngineType = 'performanceMark'
  } else {
    markEngineType = 'dateNow'
  }

  return markEngineType
}

function getIsSupportMark() {
  return true
}

// 获取存储的页面性能 Storage 信息
const performanceStorageKey = '__SHEIN_PERFORMANCE_TEST__'
function getPagePerformanceStorageData() {
  try {
    const storage = window.localStorage.getItem(`${performanceStorageKey}_SDK_PAGE`) || ''
    if (!storage) {
      return null
    }
    return JSON.parse(storage)
  } catch (error) {
    return null
  }
}

// 清空当前存储的页面 Storage 信息
function clearPagePerformanceStorageData() {
  window.localStorage.setItem(`${performanceStorageKey}_SDK_PAGE`, '')
}

/** 获取白名单中的Index */
function getWhiteListIndex(name) {
  if (!name) {
    return -1
  }

  for (let i = 0; i < apiWhiteList.length; i++) {
    if (name.indexOf(apiWhiteList[i]) > -1) {
      return i
    }
  }

  return -1
}

/** 是否白名单内 */
function checkInApiWhiteList(name) {
  const index = getWhiteListIndex(name)
  return index !== -1
}

export {
  apiWhiteList,
  jsWhiteList,
  htmlWhiteList,
  markPoint,
  getIsSupportMark,
  getMarkEngineType,
  getPagePerformanceStorageData,
  clearPagePerformanceStorageData,
  checkInApiWhiteList
}
