<template>
  <div class="bread-crumb fsp-element">
    <s-breadcrumb>
      <!-- S Home -->
      <s-breadcrumb-item to="/">
        {{ language.SHEIN_KEY_PC_15785 }}
      </s-breadcrumb-item>
      <!-- E Home -->

      <template
        v-if="['entity', 'selection', 'store'].includes(catInfo.type)"
      >
        <!-- S CCC 类目面包屑 -->
        <template v-if="navCrumbs.length">
          <s-breadcrumb-item
            v-for="(item, index) in navCrumbs"
            :key="index"
            :to="item && cccCateInfo[`${item.hrefType}_${item.hrefTarget}`] || ''"
          >
            {{ item && item.title || '' }}
          </s-breadcrumb-item>
        </template>
        <!-- E CCC 类目面包屑 -->

        <template v-else>
          <!-- S 真实分类 -->
          <template
            v-if="
              catInfo.type === 'entity' &&
                breadCrumbData &&
                breadCrumbData.length
            "
          >
            <template 
              v-for="item in breadCrumbData" 
              :key="item.cat_id"
            >
              <s-breadcrumb-item v-if="item.isForbid">
                {{ item.cat_name }}
              </s-breadcrumb-item>
              <s-breadcrumb-item
                v-else
                :to="
                  replaceHrefSpecChar({
                    href: `/${item.cat_url_name}-c-${item.cat_id}.html`,
                  })
                "
              >
                {{ item.cat_name }}
              </s-breadcrumb-item>
            </template>
          </template>
          <!-- E 真实分类 -->

          <!-- S 选品分类 -->
          <s-breadcrumb-item v-else-if="catInfo.type === 'selection'">
            {{ currentCat.select_name_multi }}
          </s-breadcrumb-item>
          <!-- E 选品分类 -->

          <!-- S 店铺列表 -->
          <s-breadcrumb-item v-else-if="catInfo.type === 'store'">
            {{ storeData.title }}
          </s-breadcrumb-item>
          <!-- E 店铺列表 -->
        </template>
      </template>

      <!-- S Daily New、Promo Discount、Search -->
      <s-breadcrumb-item v-else-if="catInfo.type === 'daily_new'">
        {{ language.SHEIN_KEY_PC_15501 }}
      </s-breadcrumb-item>
      <s-breadcrumb-item
        v-else-if="catInfo.type === 'picks'"
        :to="`/${catInfo.picksName}.html`"
      >
        {{ picksTitle }}
      </s-breadcrumb-item>
      <s-breadcrumb-item v-else-if="catInfo.type === 'search'">
        {{ searchName }}
      </s-breadcrumb-item>
      <!-- E Daily New、Promo Discount、Search -->
    </s-breadcrumb>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getCccCateInfo } from 'public/src/pages/common/ccc_public_v2'
import { SBreadcrumb } from '@shein-aidc/sui-breadcrumb'
import { SBreadcrumbItem } from '@shein-aidc/sui-breadcrumb-item'

export default {
  name: 'BreadCrumb',
  components: {
    SBreadcrumb,
    SBreadcrumbItem,
  },
  data() {
    return {
      forbidCat: ['2026', '2030', '2031', '2032', '2035', '2020', '2025'],
      breadCrumbData: [],
      cccCateInfo: {},
    }
  },
  computed: {
    ...mapState(['language', 'IS_RW']),
    ...mapGetters([
      'catInfo',
      'parentCats',
      'currentCat',
      'navCrumbs',
      'storeData',
    ]),
    /**
     * 获取 Picks 面包屑名称
     */
    picksTitle() {
      return (
        this.catInfo.picksTitle ||
        (this.IS_RW
          ? this.language.SHEIN_KEY_PC_17518
          : this.language.SHEIN_KEY_PC_16937)
      )
    },
    /**
     * 获取 Search 面包屑名称
     */
    searchName() {
      return (
        this.catInfo?.keywords
          ?.split?.(/\s+/)
          .map((v) => v && v.replace(v[0], v[0].toUpperCase()))
          .join(' ')
          .replace(/&gt;/g, '>') || ''
      )
    },
  },
  inject: ['replaceHrefSpecChar'],
  created() {
    this.getBreadCrumbData(this.parentCats)
  },
  mounted() {
    if (this.navCrumbs.length) {
      getCccCateInfo({
        cccData: this.navCrumbs,
        isBreadCrumb: true,
      }).then((res) => {
        this.cccCateInfo = res
      })
    }
  },
  methods: {
    /**
     * 获取真实分类面包屑数据
     */
    getBreadCrumbData(cats) {
      if (this.catInfo.type !== 'entity' || !Object.keys(cats).length) return

      let catName = ''

      if (cats.multi && cats.multi.cat_name) {
        catName = cats.multi.cat_name
      } else if (cats.cat_name) {
        catName = cats.cat_name
      }

      this.breadCrumbData.push({
        ...cats,
        cat_name: catName,
        cat_url_name: cats.cat_url_name,
        cat_id: cats.cat_id,
        isForbid: this.forbidCat.includes(cats.cat_id),
      })

      if (cats.children && cats.children.length) {
        return this.getBreadCrumbData(cats.children[0])
      } else {
        return this.breadCrumbData
      }
    },
  },
}
</script>

<style lang="less">
.product-list-v2 {
  .bread-crumb {
    padding: 20px 20px 20px 0;
  }
  .store-page__bread-crumb {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
