<template>
  <section 
    class="product-list-v2__section"
    role="main"
  >
    <age-limit-dialog
      v-if="showAgeLimit"
      :visible="showAgeLimit"
      :title="language.SHEIN_KEY_PC_26148"
      :content="language.SHEIN_KEY_PC_26149"
      :ok-text="language.SHEIN_KEY_PC_26150"
      :cancel-text="language.SHEIN_KEY_PC_26151"
      @expose="onAgeLimitDialogExpose"
      @ok="onAgeLimitDialogOk"
      @cancel="onAgeLimitDialogCancel"
      @close-from-icon="onCloseFormIcon"
    />
    
    <!-- 辅助功能识别 -->
    <h2
      tabindex="0"
      aria-level="2"
      :aria-label="language.SHEIN_KEY_PC_17691"
      style="position: fixed; top: -99999px; left: -99999px"
    >
      {{ language.SHEIN_KEY_PC_17691 }}
    </h2>

    <!-- 接入小分页, 上划加载 -->
    <div
      v-if="productSum > 0"
      v-infinite-scroll="handleScroll"
      infinite-scroll-disabled="scrollDisabled"
      infinite-scroll-nodata="noMoreSearchData"
      infinite-scroll-distance="infiniteScrollDistance"
      class="product-list j-expose__product-list j-product-list-info j-da-event-box product-list-new"
      code="goodsList"
      :data-abt-info="listPoskey"
      :data-promo-list="(catInfo.type === 'picks' && catInfo.picks_id) || ''"
      :data-promo-list-type="
        (catInfo.type === 'picks' && catInfo.sub_type) || ''
      "
      data-active-from="goods_list"
      :data-page-num="catInfo.page || 1"
      :data-poskey="listPoskey"
      :data-page-sort="catInfo.sort || 0"
      :data-tag_ids="catInfo.tag_ids"
      :data-attr_str="catInfo.attr_ids"
      :data-cat="catInfo.child_cat_id"
      :data-min-pri="catInfo.min_price"
      :data-max-pri="catInfo.max_price"
      :data-traceid="listTraceId"
      :data-dimension="saDimension"
      :data-request_ext="requestExt"
      :data-query-ts="queryTs"
    >
      <template 
        v-for="(item, index) in items"
        :key="item.goods_id"
      >
        <client-only>
          <!-- S 列表券坑位 -->
          <!-- 上位词列表不展示 筛选不展示 -->
          <template 
            v-if="
              ListCouponContext.showType === 'Flow' && 
                ListCouponContext.position == calcProductPos(index) && 
                ListCouponContext?.couponList?.length && !hasFilter && !(switchBtn.productsOriginOpen && productsOriginData?.length)
            "
          >
            <FlowSearchCoupon
              class="product-list__item-new"
              :position="calcProductPos(index)"
              :cate-coupon="ListCouponContext"
              :language="language"
              @change-cate-coupon="changeCateCoupon"
            />
          </template>
          <!-- E 列表券坑位 -->
        </client-only>

        <!-- S 广告流banner -->
        <!-- cccx -->
        <CccxComp 
          v-if="cccxFlowAdData[calcProductPos(index)]"
          :cccx-context="cccxFlowAdContext"
          :content="[cccxFlowAdData[calcProductPos(index)]]"
          class="product-list__item not-fsp-element product-list__item-new"
        />
        <!-- 老ccc -->
        <div
          v-else-if="flowAdData[calcProductPos(index)]"
          class="product-list__item not-fsp-element product-list__item-new"
        >
          <FlowAd
            :context="flowAdContext"
            :prop-data="flowAdData[calcProductPos(index)]"
            :ccc-cate-info="flowAdCccCate"
            :ada-level="0"
          />
        </div>
        <!-- E 广告流banner -->

        <client-only>
          <!-- S 搜了又搜 -->
          <ProductListRelatedSearch
            v-if="flowRelatedSearch[calcProductPos(index)]"
            :position="calcProductPos(index)"
            :prop-data="flowRelatedSearch[calcProductPos(index)]"
          />
          <!-- E 搜了又搜 -->


          <!-- 搜索商品分层提示语 -->
          <SearchHierarchyTitle
            v-if="!switchBtn.onlyOne && searchHierarchyIndex === index"
            :not-priority="catInfo.page == 1 && index === 0"
            :current-page="catInfo.page"
            :search-word="catInfo.keywords"
            :rec-tip-index-new="recTipIndexNew"
            :language="language"
          />
          <!-- 店铺页关注组件插坑 -->
          <template v-if="hasflowStoreFollowingData && flowStoreFollowingData[slotStart + index]">
            <div
              v-for="(data, idx) in flowStoreFollowingData[slotStart + index]"
              :key="idx"
              class="product-list__item product-list__item-new"
            >
              <FlowStoreFollowing
                :data="data"
                :slot-idx="data.idx"
              />
            </div>
          </template>
        </client-only>
        
        <s-product-card
          ref="goodsItem"
          class="product-list__item product-list__item-new"
          :index="Number(index)"
          :item="item"
          :no-img-lazy="index < 10"
          :ada-level="0"
          :config="productItemShowConfig"
          :goods-item-info="goodsItemInfo"
          :language="language"
          :constant-data="constantData"
          :bottom-info-style="{ minHeight: '30px' }"
          :loading="quickViewLoading[item.goods_id]"
          :product-design-width="255"
          :open-target="openTarget"
          :report-metrics="reportMetrics"
          @add-bag-before-request="handleAddTobagBtn"
          @add-wish-succ="handleAddWishSucc"
          @cancel-wish="handleCancelWish"
          @add-wishlist-group="handleAddWishlistGroup"
          @submit-add-bag-analysis="handleSubmitAddBagAnalysis"
          @click-add-to-bag="openQuickView"
          @open-quick-add="openQuickView"
          @relate-color-hover="autoCloseRecoPanel"
          @open-buy-box-drawer="openBuyBoxDrawer"
        >
          <!-- 商品推荐 -->
          <template
            #clientGoodsImg
          >
            <RecommendPanel
              :ref="getRecoPanelRefName(item)"
              :constant-data="constantData"
              :list-abt-result="listAbtResult"
              :language="language"
              :item-language="language"
              :config="cccConfig.feedReco"
              :item="item"
              :cat-info="catInfo"
              :use-bff-api="useBffApi"
              :list="showRecoGoods"
              :atom-params="atomParams"
              :list-poskey="listPoskey"
              :panel-item-show-config="panelItemShowConfig"
              :dialog-item-show-config="productItemShowConfig"
              new-product-card
              @close="closeRecoPanelByUser"
            />
          </template>
        </s-product-card>
      </template>

      <!-- 如果当前是最后一页 -->
      <template v-if="!switchBtn.onlyOne && (catInfo.page == Math.ceil(sumForPage / catInfo.limit))">
        <client-only>
          <!-- S 列表券坑位 -->
          <template v-if="ListCouponContext.showType === 'Flow' && ListCouponContext.position == flowReatedSearchPos[0] && !(switchBtn.productsOriginOpen && productsOriginData && productsOriginData.length)">
            <FlowSearchCoupon
              :position="flowReatedSearchPos[0]"
              :cate-coupon="ListCouponContext"
              :language="language"
              @change-cate-coupon="ListCouponContext = $event"
            />
          </template>

          <!-- E 列表券坑位 -->
        </client-only>

        <!-- 广告流banner 第一个坑位大于商品总数要显示第一个 -->
        <CccxComp 
          v-if="cccxFlowAdData[cccxFlowAdPos[0]] && cccxFlowAdPos.length && cccxFlowAdPos[0] > sumForPage"
          :cccx-context="cccxFlowAdContext"
          :content="[cccxFlowAdData[cccxFlowAdPos[0]]]"
          class="product-list__item not-fsp-element product-list__item-new"
        />
        <div
          v-else-if="flowAdPos.length && flowAdPos[0] > sumForPage"
          class="product-list__item not-fsp-element"
        >
          <FlowAd
            :context="flowAdContext"
            :prop-data="flowAdData[flowAdPos[0]]"
            :ccc-cate-info="flowAdCccCate"
            :ada-level="0"
          />
        </div>

        <client-only>
          <!-- 搜了又搜 第一个坑位大于商品总数要显示第一个 -->
          <ProductListRelatedSearch
            v-if="
              flowReatedSearchPos.length && flowReatedSearchPos[0] > sumForPage
            "
            :prop-data="flowRelatedSearch[flowReatedSearchPos[0]]"
          />
        </client-only>
      </template>

      <!-- 小分页功能使用的loading -->
      <client-only>
        <ProductListLoading
          v-if="abtSearchSubPageOpen"
          class="load-more-loading"
          :show="request.loading"
        />
      </client-only>
      <client-only>
        <!-- 下一页 -->
        <ProductListNextHolder
          v-if="!switchBtn.onlyOne && (catInfo.page != Math.ceil(sumForPage / catInfo.limit)) && !abtSearchSubPageOpen"
          @click-next-page="handlePageChange"
        />
      </client-only>
    </div>

    <!-- 空值处理 -->
    <ResultEmpty v-else-if="!isShowFilterResult" />
    
    <client-only>
      <!-- 分页页码组件 -->
      <slot default>
        <s-pagination
          v-if="!hidePagination &&!isShowFilterResult"
          :total-pages-lang="language.SHEIN_KEY_PC_18002"
          :current-page="catInfo.page"
          :total="sumForPage"
          :page-size="catInfo.limit"
          :align="paginationAlign"
          :nextmove="false"
          @page-change="handlePageChange"
        />
      </slot>     

      <SearchCouponModal  
        v-if="!showAgeLimit && ListCouponContext.showType === 'Modal'"
        :cate-coupon="ListCouponContext"
        type="searchModal"
      />
    </client-only>


    <!-- filterResult -->
    <FilterResult 
      v-if="isShowFilterResult"
      :loading="request.loading"
      @filter-change="filterResultChange"
    />
  </section>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { parseQueryString } from '@shein/common-function'
import { ClientOnly } from '@sheinfe/vue-client-only'

import ProductListNextHolder from './ProductListNextHolder'
import ResultEmpty from './ResultEmpty.vue'
import FilterResult from 'public/src/pages/product_list_v2/components/filter/FilterResult.vue'

import { SPagination } from '@shein-aidc/sui-pagination'
import { GoodsItemInfo } from 'public/src/services/goodsItemInfo'
import { getCccCateInfo } from 'public/src/pages/common/ccc_public_v2'

import { windowScrollInstance } from 'public/src/services/expose/index.js'
import { daEventCenter } from 'public/src/services/eventCenter'
import SProductCard from 'public/src/pages/components/productItemV3/MultipleImageRowCard.vue'
import { abtservice } from 'public/src/services/abt'

import mixin from './filter/mixins'
// 注意: 当前 ProductItem 与 ProductItemV3 公用相同的 mixin
import itemMixin from 'public/src/pages/components/productItem/mixin'
import RecommendPanel from 'public/src/pages/components/productItem/components/RecommendPanel.vue'
import recommendPanelMixin from '../../components/productItem/mixins/recommendPanel'
import FlowAd from './FlowAd.vue'
import CccxComp from './CccxComp.vue'

import { groupDialog } from 'public/src/pages/components/wishlist'
import { AgeLimitDialog, ageLimitAnalysis } from 'public/src/pages/components/age-limit-dialog'

import { filterCouponWithin24Hours, checkCateCouponWithin24Hours } from 'public/src/pages/product_list_v2/components/coupon/utils.js'
import { RECEIVED_MODE_ENUM } from './coupon/enum'
import { souceFormat as couponSouceFormat } from 'public/src/pages/components/coupon/mall/utils.js'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'
import { PAGE_NAME_MAP_PAGE_KEY } from '../utils/index'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { SIMetric } from 'public/src/pages/common/monitor/index.js'
import { preFetchGoodsPromise } from '@/public/src/services/pre_requests/common'

const daEventExpose = daEventCenter.getExposeInstance()

const isSearchPageFn = (pageName) => pageName === 'page_search'

// 列表abt 埋点上报所需
// 1.埋点只读取客户端 abt 服务请求过且有结果的数据..未在 poskey.js 列表内被请求过的不会有结果..
// 2.abt首次请求时列表 pageName 还没好, poskey.js 的 case 未命中..
// ∴ 这个文件有很多 abtservice 请求仅为支撑埋点..
// 这块需要好好优化..
const listAbtSceneStr = [
  // 'OtherNewInSort',
  'SearchPageSort',
  // Picks Nav
  // 标签云
  // 'CloudTagContent', // 已推全-写死值: cloud_tag=1&rule_id=tag_abt:pde|resort:v1|recplt_debug:1
  'ListAttrSequence',
  // 搜索筛选
  'Searchfilter',
  // 视频icon
  'VideoIcon',
  // 优选卖家
  'AllListPreferredSeller',
]
// 注意：新增abt都加这里！！
const newListAbtSceneStr = [
  'FollowLabel',
  'imageLabel',
  'SameLabel',
  'OneTwoTitle',
  'NewPosition',
  'UserBehaviorLabel',
  'listReview', 
  'selectedreview',
  'selectedreviewJson',
  'MostPopular',
  'PromotionalBelt',
  'RankingList',
  'SelectClassSortJson',
  'DetailShopItemList',
  'CartShopItemList',
  'RealClassSortJson',
  'cateName',
  'ListReco', 
  'SearchReco',
  'RealtimeFeedbackJson',
  'AdultProductAge',
  'discountLabel',
  'greysellingPoint',
  'sheinappwordsize',
  'goodsSpace',
  'labelColumn',
  'listSpuPic',
  'searchSpuPic',
  'NewInSortJson',
  'DailyNewSortJson',
  'DailySortJson',
  'SortSellingPointJson',
  'sheincluboptionsJson',
]
const searchWordsSceneStr = [
  'SearchSuggestwordFeedback',
  // 'SearchCategoryWord', 推全type=B
  'SearchRecTips',
]
// 注意：新增abt都加这里！！
const newSearchWordsSceneStr = [
  'SearchSuggestNew',
  'SearchDefaultNew',
  'SearchHotNew',
  'cateName',
  'searchSpuPic',
  'newpresearch',
  'newpresearchlenovo',
  'newpresearchicon'
]
export default {
  name: 'ProductList',
  inject: ['getGoodsUrl'],
  components: {
    AgeLimitDialog,
    SProductCard,
    SPagination,
    ProductListNextHolder,
    ClientOnly,
    ResultEmpty,
    RecommendPanel,
    FlowAd,
    CccxComp,
    FilterResult,
    SearchHierarchyTitle: defineAsyncComponent(() => {
      return import(/* webpackChunkName: "product_list_flow" */ './SearchHierarchyTitle.vue')
    }),
    ProductListRelatedSearch: defineAsyncComponent(() => {
      return import(/* webpackChunkName: "product_list_flow" */ './ProductListRelatedSearch.vue')
    }),
    FlowSearchCoupon: defineAsyncComponent(() => {
      return import(/* webpackChunkName: "product_list_flow" */ './coupon/FlowSearchCoupon.vue')
    }),
    SearchCouponModal: defineAsyncComponent(() => {
      return import(/* webpackChunkName: "product_list_flow" */ './coupon/SearchCouponModal.vue')
    }),
    ProductListLoading: defineAsyncComponent(() => {
      return import(/* webpackChunkName: "product_list_loading" */'public/src/pages/components/ccc/base/ProductListLoading.vue')
    }),
    FlowStoreFollowing: defineAsyncComponent(() => {
      return import(/* webpackChunkName: "flow_store_following" */'public/src/pages/store_pages/components/FlowStoreFollowing.vue')
    }),
  },
  mixins: [mixin, itemMixin, recommendPanelMixin],
  props: {
    request: { 
      type: Object,
      default: () => ({
        type: '',
        query: { page: 1 },
        loading: false
      }),
    },
    infiniteLoadNodata: {
      type: Boolean,
      default: false,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    productsOriginData: {
      type: Array,
      default: () => [],
    },
    productsOriginSum: {
      type: Number,
      default: 0,
    },
    switchBtn: { // 适用于同一个页面有多个该ProductList实例【搜索页面的上位词商品搜索--hympernymGoods】
      type: Object,
      default: () => ({
        productsOriginOpen: false, // 控制不同数据源商品
        onlyOne: false, // 多个实例，弹层,banner等控制只用一个
      }),
    },
    usedBy: {
      type: String,
      default: 'list',
    },
    isShowFilterResult: {
      type: Boolean,
      default: false,
    },
    flowStoreFollowingData: {
      type: Object,
      default: () => ({}),
    },
    queryTs: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      expose: null,
      goodsItemInfo: null,
      listPoskey: '',
      listTraceId: '',
      goodsMap: {},

      atomParams: {},

      // 商卡配置项
      productItemShowConfig: {},

      flowAdCccCate: {},
      quickViewLoading: {}, // quickView的loading状态

      // 未满18岁弹窗
      showAgeLimit: false,
      buyBoxDrawerInstance: null,
      buyBoxGoodsId: '',
      // key为数字，则代表领券坑位，为couponModal则为直接发放弹窗
      ListCouponContext: {}, // 搜索未登录领券
      reportMetrics: { img: true, addBag: true, jumpDetail: true }
    }
  },
  computed: {
    ...mapState([
      'language',
      'IS_RW',
      'LAZY_IMG',
      'LAZY_IMG_SOLID_COLOR',
      'LAZY_IMG_SQUARE',
      'currency',
      'lang',
      'GB_cssRight',
      'results'
    ]),
    ...mapGetters([
      'goods',
      'cccListOperation',
      'cccxConfig',
      'catInfo',
      'tracking',
      'request_ext',
      'listAbtResult',
      'sum',
      'sumForPage',
      'searchKeywords',
      'cccConfig',
      'filterData',
      'sheinClubInfo',
      'atomicParams',
      'mallInfo',
      'promotionInfoFromServer',
      'cardConfig',
      'recommendPanelFields'
    ]),
    productList(){
      if (this.switchBtn.productsOriginOpen) {
        return this.productsOriginData
      } else {
        // 小分页埋点列表曝光问题,
        if (this.abtSearchSubPageOpen && this.catInfo.page <= this.limitPage) {
          this.searchLoadMoreReport()
        }
        return this.goods
      }
    },
    productSum(){
      return this.productsOriginSum || this.sum
    },
    items() {
      let index = 0
      return this.productList.map((item) => {
        item.index = index
        const {
          src_module,
          src_identifier,
          src_tab_page_id,
          attr_ids = '',
          url_from,
          store_code,
          search_type,
          keywords,
          pageListType
        } = this.catInfo

        let biQueryParams = {}

        // 门店搜索直接使用
        if (store_code && search_type === 'store' && keywords) {
          biQueryParams = {
            src_identifier: src_identifier,
            src_module: src_module,
            src_tab_page_id,
            attr_ids,
          }
        } else {
          biQueryParams = {
            src_identifier: url_from
              ? `uf=${url_from}`
              : encodeURIComponent(src_identifier || ''),
            src_module: url_from ? 'ads' : src_module,
            src_tab_page_id,
            attr_ids,
          }
        }

        const mallCode = item.mall_code ? { mallCode: item.mall_code } : {}
        this.goodsItemInfo.dealGoodsDetailUrl({
          goodsItem: item,
          query: {
            ...biQueryParams,
            ...mallCode,
            pageListType: pageListType
          },
        })
        if (!this.$isServer) {
          item.relatedColor &&
            item.relatedColor.forEach((relateColorItem) => {
              const mallCode = relateColorItem.mall_code
                ? { mallCode: relateColorItem.mall_code }
                : {}
              relateColorItem.index = index
              this.goodsItemInfo.dealGoodsDetailUrl({
                goodsItem: relateColorItem,
                query: {
                  ...biQueryParams,
                  ...mallCode,
                  pageListType: pageListType
                },
              })
            }) 
          this.goodsMap[item.goods_id] = item
        }
        index += 1
        return item
      })
    },
    hasflowStoreFollowingData({ flowStoreFollowingData, usedBy }) {
      if (usedBy !== 'store') return false
      return Object.keys(flowStoreFollowingData || {}).length
    },
    slotStart({ catInfo }) {
      const { page, limit = 120 } = catInfo || {}
      const start = (page - 1) * limit
      return start
    },
    // 搜索召回分层, 优先级为2进行分层
    searchHierarchyIndex() {
      if (this.listAbtResult?.SearchRecTips?.p !== 'test_group' || this.catInfo.search_type === 'store' ) {
        return -1
      }
      const index =  this.items.findIndex(
        (item) => (`${item.ext?.recall_mark}`.split('_') || [])[1] > 1
      )
      if(index === 0 && this.listAbtResult?.NoResultPage?.p?.RecTipsPage === 'new') { // 命中新实验，当前列表上面不展示
        return -1
      }
      return index
    },
    recTipIndexNew () {
      return this.listAbtResult?.NoResultPage?.p?.RecTipsPage === 'new'
    },
    requestExt() {
      return Object.keys(this.request_ext)
        .map((key) => `${key}_${this.request_ext[key]}`)
        .join('|')
    },
    saDimension() {
      return this.catInfo.sortScene && Object.keys(this.tracking).length
        ? `${this.IS_RW ? 'R' : 'S'}Pc${this.catInfo.sortScene}\`${
            this.tracking.singleCatType
          }\`${this.tracking.singleCatId}`
        : ''
    },
    flowAdContext() {
      return this.cccListOperation?.pc_list_flow || {}
    },
    // 商品流banner ccc 数据
    flowAdData() {
      if (!this.flowAdContext.content || !this.flowAdContext.content.length)
        return {}
      const flowAdData = {}
      this.flowAdContext.content.forEach((item) => {
        const pos = item.content?.props?.items[0]?.position || ''
        if (pos) {
          flowAdData[pos] = item
        }
      })
      return flowAdData
    },
    flowAdPos() {
      return Object.keys(this.flowAdData)
    },
    cccxFlowAdContext() {
      return this.cccxConfig?.flow_list_zone || {}
    },
    // 商品流banner cccx 数据
    cccxFlowAdData() {
      if (!this.cccxFlowAdContext?.content?.length) return {}
      const cccxFlowAdData = {}
      this.cccxFlowAdContext.content.forEach((item) => {
        const pos = item?.placeHolderPosition
        if (pos) {
          cccxFlowAdData[pos] = item
        }
      })

      return cccxFlowAdData
    },
    cccxFlowAdPos() {
      return Object.keys(this.cccxFlowAdData)
    },
    guidedSearchContext() {
      return this.cccListOperation?.pc_guided_search || {}
    },
    // 商品流中的搜了又搜ccc数据
    flowRelatedSearch() {
      if (
        !this.guidedSearchContext.content ||
        !this.guidedSearchContext.content.length
      )
        return {}
      const flowRelatedData = {}
      let index = 0
      this.guidedSearchContext.content.forEach((item) => {
        const data = item.content?.props?.items[0]
        if (data && data.contentType == 'SearchFeedHotword') {
          index += 1
          data['index'] = index
          flowRelatedData[data.position] = data
        }
      })
      return flowRelatedData
    },
    flowReatedSearchPos() {
      return Object.keys(this.flowRelatedSearch)
    },
    pageInfo() {
      // TODO 好像这个函数没用到
      const page = this.catInfo.page || 1
      const end = Math.ceil(page / 10) * 10
      const start = end - 9
      return {
        start: start == page ? start - 1 : start,
        end: end == page ? end + 1 : end,
      }
    },
    isSearchPage() {
      return isSearchPageFn(this.catInfo.pageName)
    },
    // 当前是否为筛选之后的页面
    hasFilter() {
      return Object.keys(this.catInfo).some((key) => {
        const filterAttr = [
          'daily',
          'child_cat_id',
          'attr_values',
          'min_price',
          'max_price',
        ]
        return this.catInfo[key] && filterAttr.includes(key)
      })
    },
    // 窗口打开类型
    openTarget () { 
      const carAbtComplete = gbCommonInfo.CARD_ABT_COMPLETE === 'on'
      return carAbtComplete ? '_blank' : (this.listAbtResult?.PcListJump?.p?.PcListJumpNew === 'B' ? '_blank' : '_self')
    },
    // 页码位置
    paginationAlign () {
      return this.listAbtResult?.PcListView?.p?.PcListViewNew === 'B' ? 'center' : 'right'
    },
    abtSearchSubPageOpen() {
      // 开启小分页
      return this.isSearchPage && !this.switchBtn.onlyOne && this.listAbtResult?.SearchPageSize?.p?.page_divide === 'true'
    },
    // 小分页 大页范围限制
    limitPage() {
      return Math.ceil(this.listAbtResult?.SearchPageSize?.p?.effect_size / 120)
    },
    // 小分页的无限滚动请求下一页的滚动距离
    infiniteScrollDistance () {
      return this.listAbtResult?.SearchPageSize?.p?.search_request_height || '12000'
    },
    scrollDisabled(){
      return this.request.loading || !this.abtSearchSubPageOpen
    },  
    noMoreSearchData() {
      return !this.abtSearchSubPageOpen || this.infiniteLoadNodata
    }
  },
  created() {
    this.goodsItemInfo = new GoodsItemInfo({
      listLanguage: this.language,
      getGoodsUrl: this.getGoodsUrl,
    })

    this.productItemShowConfig = this.cardConfig

    this.showAgeLimit = this.catInfo.hasAdultProduct && !this.switchBtn.onlyOne

    this.atomParams = this.getAtomParams()
    
  },
  mounted() {
    // eslint-disable-next-line @shein-aidc/abt/abt
    abtservice.getUserAbtResult({ posKeys: listAbtSceneStr.join(), newPosKeys: newListAbtSceneStr.join() })
    this.queryObj = parseQueryString(location.search) // 初始化进页面解析URL入参，解决url带筛选，但是小分页请求无相关筛选参数值问题
    this.getAbtPosKey(this.listAbtResult)
    this.getTraceId()

    this.setElAttr('all')
    this.initExpose()

    // 兼容客户端渲染不需要水合，比如店铺页
    // this.onHydrated('all')

    this.getFlowAdCate()

    this.handelFlowCateCouponContext()
    this.loadBuyBoxDrawerInstance()
  },
  methods: {
    ...mapMutations(['jumpUrl']),
    // 触发当前页中的小分页请求
    handleScroll() {
      // 针对第一次搜索结果时，数量少于小分页的
      if (this.abtSearchSubPageOpen && this.listAbtResult?.SearchPageSize?.p?.sub_page_size > this.sumForPage && this.catInfo.page == 1 && !this.request.type) {
        return 
      }
      // 非小分页(只会出现在第一页), 不触发这个
      if(!this.abtSearchSubPageOpen || this.catInfo.page > this.limitPage) {
        return 
      }
      // 加载过程中和有展示view more情况下，不能调用loadMore(),goods为空，表示当前小分页已经没有更多数据了
      if (!this.request.loading && this.goods.length) {
        this.$emit('loadMore', { queryObj: { ...this.queryObj, isPageLoadMore: true } })
      }
    },
    async setElAttr(type) {
      // app.vue调用
      if(type === 'all') {
        this.$refs?.goodsItem?.forEach((goodsItem) => {
          // 因价格等商品信息动态获取，故在数据获取后更新item的dom上的数据
          goodsItem.setElAttr()
        })
      } else {
        // 只设置已水合的商品项
        this.$refs?.goodsItem[type]?.setElAttr()
      }
    },

    // 请求原子服务参数
    getAtomParams() {
      const { type, pageName, entity_id, select_id } = this.catInfo
      const { isPaid } = this.sheinClubInfo || {}

      const cateId = type == 'entity'  
        ? entity_id 
        : type == 'selection' 
          ? select_id 
          : undefined
      const cccParams = { cateId }

      return {
        fields: this.recommendPanelFields,
        pageKey: PAGE_NAME_MAP_PAGE_KEY[pageName] || pageName,
        cccParams,
        sceneKey: 'TWO_IN_A_ROW',
        isPaid
      }
    },
    initExpose() {
      /**
       *  绑定scroll事件，动态获取多颜色信息，预加载副图
       **/
      windowScrollInstance.observe(
        {
          section: [
            {
              code: 'product-list-v2',
              container: '.j-expose__product-list',
              content: '.j-expose__product-item',
              target: '.j-expose__product-item-img',
              averageContent: false,
            },
          ],
        },
        ({ exposeDoms: targets }) => {
          // 多颜色切换时goodsMap没有对应的goodsId，不需要请求
          let inScreenGoods = []
          targets.forEach((el) => {
            let goodsId = el.getAttribute('data-id')
            if (!this.goodsMap[goodsId]) return
            inScreenGoods.push(this.goodsMap[goodsId])
          })
          // preload relatecolor info，relatecolor goods img
          if (inScreenGoods.length > 0) {
            const { scene, fields, cccParams, isPaid } = this.atomicParams || {}
            const { pageKey, sceneKey } = scene || {}
            const { AllListStarReview, listflashSale } = this.listAbtResult || {}
            this.goodsItemInfo.getRelatedColorAtomicInfo({ 
              goods: inScreenGoods,
              colorAtomFields: { ...(fields || {}), baseInfo: true },
              pageKey,
              sceneKey,
              cccParams,
              isPaid,
              mallInfo: this.mallInfo,
              promotionInfoFromServer: this.promotionInfoFromServer,
              language: this.language,
              abtInfo: {
                newProductCard: true,
                starReview: AllListStarReview?.p?.liststar,
                listflashSale: listflashSale?.p?.listflashSale == 'flashSale'
              }
            })
          }
        }
      )

      preFetchGoodsPromise().then(() => {
        this.triggerExpose()
      })
    },
    // 发送商品埋点
    triggerExpose() {
      this.$nextTick(() => {
        daEventExpose &&
          daEventExpose.subscribe({
            keycode: `productListExposeCode\`${
              this.productItemShowConfig.daEventExposeId || ''
            }|2-10-2`,
            type: 'list', // 当前code是属于列表类型的吗
          })
      })
    },

    // 小分页loadmore加载, goods-list埋点上报处理
    searchLoadMoreReport () {
      setTimeout(() => {
        daEventExpose &&
          daEventExpose.subscribe({
            keycode: `productListExposeCode\`${
              this.productItemShowConfig.daEventExposeId || ''
            }|2-10-2`,
            type: 'list', // 当前code是属于列表类型的吗
          })
      }, 200)
    },
    getExposeType () {
      const { type, child_cat_id, search_type } = this.catInfo
      const expose_type = type === 'search' || search_type === 'store' ? 4 : (child_cat_id ? 2 : 1)
      return expose_type
    },
    onAgeLimitDialogExpose () {
      const expose_type = this.getExposeType()
      ageLimitAnalysis.exposePopup({ expose_type })

    },
    onAgeLimitDialogOk () {
      const expose_type = this.getExposeType()
      const button_type = 1
      ageLimitAnalysis.clickPopup({ expose_type, button_type })

      this.showAgeLimit = false
    },
    onAgeLimitDialogCancel () {
      const expose_type = this.getExposeType()
      const button_type = 2
      ageLimitAnalysis.clickPopup({ expose_type, button_type })

      // this.showAgeLimit = false
    },
    onCloseFormIcon () {
      const expose_type = this.getExposeType()
      const button_type = 3
      ageLimitAnalysis.clickPopup({ expose_type, button_type })
    },

    openQuickView({ item, goodsItem, target, imgRatio }) {
      this.autoCloseRecoPanel() 
      this.quickViewLoading[[goodsItem.goods_id]] = true
      this.handleFeedbackDataFetch(item)
      let detailUrlExtendParam = {}
      const {
        src_module,
        src_identifier,
        src_tab_page_id,
        url_from,
        store_code,
        search_type,
        keywords,
      } = this.catInfo
      // 门店搜索直接使用
      if (store_code && search_type === 'store' && keywords) {
        detailUrlExtendParam = {
          src_identifier: src_identifier,
          src_module: src_module,
          src_tab_page_id,
        }
      } else {
        detailUrlExtendParam = {
          src_identifier: url_from
            ? `uf=${url_from}`
            : encodeURIComponent(src_identifier || ''),
          src_module: url_from ? 'ads' : src_module,
          src_tab_page_id,
        }
      }
      import('public/src/pages/common/quickView').then(
        ({ default: quickView }) => {
          quickView.show({
            imgRatio,
            goodsId: goodsItem.goods_id,
            mallCode: goodsItem.mall_code,
            lockmall: !!this.catInfo.lockMall,
            pageListType: this.catInfo.pageListType,
            config: {
              showBestDealLabel: true,
              showFollowBeltByOrigin: true,
              showEstimatedPrice: !item.pretreatInfo?.suggestedSalePriceConfigInfo?.config?.cartHideEstimatedPrice,
              detailUrlExtendParam,
            },
            analysis: {
              sourceTarget: target,
              pageType: 'ItemList',
              sa: {
                code: 'goodsList',
                activity_from: 'goods_list',
              },
              extendAnalysis: {
                isNew: goodsItem?.pretreatInfo?.badgeInfo?.isNewTag ? 1 : 0,
                traceid: gbExposeTraceid('getComponent', {
                  componentName: 'goodslist',
                }),
                cat_info: this.catInfo,
              },
              index: item.index // 埋点需要
            },
            callback: {
              handleOpenQuickViewDone: () => {
                this.resetRecoPanel(item)
                this.quickViewLoading[[goodsItem.goods_id]] = false
              },
              handleOpenLogin(fetchAddLike) {
                SHEIN_LOGIN.show({
                  cb: () => fetchAddLike(),
                  from: 'goods_list',
                })
              },
              handleAddWishDone: (goodsId) => {
                this.recoPanelOperationConfig.isFavSuccess = true
                const goodsItem = this.productList.find((_) => _.goods_id == goodsId)
                goodsItem &&
                  this.goodsItemInfo.syncWishStatus({
                    goodsItem,
                    params: { status: true },
                  })
              },
              closeQuickView: () => {
                // 未加车，用户主动关闭弹窗
                this.showRecoPanel(item)
              },
              handleAddToCartDone: (res) => {
                this.recoPanelOperationConfig.isCarSuccess = true
                this.showRecoPanel(item)
                window.cart_module
                  ? window.cart_module.recart(res)
                  : window.location.reload()
              },
              handleCancelWishDone: () => {
                this.recoPanelOperationConfig.isFavSuccess = false
              },
            },
          })
        }
      )
    },
    // 如果实时反馈abt打开，前置请求实时反馈弹窗数据
    handleFeedbackDataFetch(item) {
      // 是否搜索页 
      const pageName = this.catInfo.pageName
      const isSearchPage = pageName === 'page_search'
      const { SearchReco = {}, ListReco = {} } = this.listAbtResult ?? {}
      // 修复历史问题，如果只开了搜索结果列表，泛列表abt没开就会导致
      if(isSearchPage) {
        if(!SearchReco?.p?.ShowSearchReco) {
          return
        }
      }else {
        if(!ListReco?.p?.ShowListReco) {
          return
        }
      }
      // if(isSearchPage && !SearchReco?.p?.ShowSearchReco) {
      //   return
      // } else if(!ListReco?.p?.ShowListReco) {
      //   return
      // }
      // 前置实时反馈请求，参见wiki：pageId=1308184808
      this.getRecoGoods(item)
    },
    handleAddTobagBtn({ goodsItem = {} } = {}) {
      gbAddBagTraceFrom('setProductFrom', {
        from: 'list',
        goods_id: goodsItem.goods_id,
      })
    },
    // submit add to bag 埋点
    handleSubmitAddBagAnalysis({
      target,
      code,
      from,
      response,
      goodsItem,
      reason,
      attrs,
      selectedSize,
      skuCode,
    }) {
      daEventCenter.triggerNotice({
        daId: '1-7-1-1',
        target,
        extraData: {
          code,
          from,
          result: response,
          title: '',
          postData: {
            goods_id: goodsItem.goods_id,
            sku: goodsItem.goods_sn,
            spu: goodsItem.productRelationID || goodsItem.goods_sn,
            price: GB_ga_transformPrice(goodsItem?.salePrice?.amount),
            quantity: 1,
            catId: goodsItem.cat_id || '',
            variant: selectedSize.attr_value_en || '',
            amount: goodsItem?.salePrice?.amount,
            unit_discount: goodsItem.unit_discount || '',
            index: goodsItem.index || 0,
            attrs,
            skuCode,
          },
          faultReason: reason,
          isNew: goodsItem?.pretreatInfo?.badgeInfo?.isNewTag ? 1 : 0,
          traceid: gbExposeTraceid('getComponent', {
            componentName: 'goodslist',
          }),
          cat_info: this.catInfo,
        },
      })
    },
    handleAddWishSucc() {
      this.autoCloseRecoPanel()
      if (typeof fastwish != 'undefined') {
        fastwish.reAddwish()
      }
    },
    // productitem 中取消收藏
    handleCancelWish() {
      this.autoCloseRecoPanel()
    },
    handleAddWishAnalysis({
      target,
      code,
      from,
      result,
      action,
      sku,
      goods_id,
      detail,
      index,
      mall_code,
    }) {
      daEventCenter.triggerNotice({
        daId: '1-7-1-2',
        target,
        extraData: {
          code,
          result,
          mall_code: mall_code || '',
          postData: {
            action, // 选中发1, 取消发0
            sku,
            goods_id,
            detail,
            index,
            ...(this.productItemShowConfig.showQuickShip ? { quickShip: detail.quickship } : {})
          },
          from,
          cat_info: this.catInfo,
        },
      })
    },
    handleAddWishlistGroup({ goodsItem = null } = {}) {
      if (!goodsItem) return

      daEventCenter.triggerNotice({
        daId: '1-4-1-9',
      })
      groupDialog().then(dialog => dialog.open(
        {
          type: 4,
          goodsIds: [goodsItem.goods_id],
          useFrom: '列表页',
        }, () => {}))
    },
    getFlowAdCate() {
      if (!Object.values(this.flowAdData).length) return

      getCccCateInfo({
        cccData: [{ operations: Object.values(this.flowAdData) }],
      }).then((res) => {
        this.flowAdCccCate = res
      })
    },
    /** 获取埋点相关数据 */
    async getAbtPosKey(abtInfo) {
      // 数据埋点从node提
      let keyList = Object.values(abtInfo)
        .filter((_) => _ && [...listAbtSceneStr, ...newListAbtSceneStr].includes(_.sceneStr))
        .map(({ sceneStr }) => sceneStr)
      // 前端搜索词实验埋点单独取
      if (this.catInfo.type === 'search') {
        // eslint-disable-next-line @shein-aidc/abt/abt
        const results = await abtservice.getUserAbtResult({
          posKeys: searchWordsSceneStr.join(),
          newPosKeys: newSearchWordsSceneStr.join()
        })
        keyList = keyList.concat(
          Object.keys(results).filter((_) => results[_]?.posKey)
        )
      }
      // 非 recommend 不上报排序实验
      if (this.catInfo.sort && this.catInfo.type !== 'search') {
        keyList = keyList.filter((_) => !/^Sort|Sort$/.test(_))
      }
      
      this.listPoskey = [...keyList, 'listFilterAbt'].join()
    },
    getTraceId() {
      this.listTraceId = gbExposeTraceid('getComponent', {
        componentName: 'goodslist',
      })
    },
    handlePageChange(next) {
      this.queryObj = parseQueryString(location.search)
      this.queryObj.page = next

      // 关闭优惠券tip，切页码不再展示
      if (sessionStorage.closeCouponTip) {
        delete this.queryObj.ShowTip
      }

      // mixin方法
      this.handleUrl()

      this.$emit('pageChange', { queryObj: this.queryObj })
    },
    calcProductPos(index) {
      const { page, limit } = this.catInfo
      return (page - 1) * limit + (index + 1)
    },
    // 搜索领券
    handelFlowCateCouponContext() {
      const couponSlice = (list) => {
        if (list?.length > 3) {
          return list.slice(0, 3)
        } else {
          return list ?? []
        }
      }
      const isLogin = isLoginFn()

      // 展示形式，弹窗 Modal  流式 Flow 
      // showType: '',
      // 插坑位置 showType === Flow 时有值
      // position: -1,
      // 券包券列表
      // packageCoupon: null,
      // 分类列表
      // searchCoupon: null
      let { showType, position, cateCoupon, packageCoupon, cateId } = this.cccListOperation?.pc_list_coupon ?? {}
      
      cateCoupon = cateCoupon ?? []
      packageCoupon = packageCoupon ?? []

      const member_id = UserInfoManager.get({ key: 'memberId', actionType: 'list/coupon' }) ?? ''

      const pageName = this.catInfo.pageName
      const result = {
        pageName,
        // 展示形式，弹窗 Modal  流式 Flow
        showType,
        // 插坑位置 showType === Flow 时有值
        position,
        cateId, // 该页面的cateId  搜索页赋值
        receivedMode: '',
        packageCoupon: [],
        cateCoupon: [],
        couponList: []
      }

      const selectPage = ['page_real_class', 'page_select_class']
      // 是否选品页
      const isSelectPage = selectPage.includes(pageName)
      // 是否搜索页
      const isSearchPage = pageName === 'page_search'

      if (!isSelectPage && !isSearchPage ) return false
      if (!showType) return false

      const { SearchListCoupon, SearchSceneCoupon } = this.listAbtResult

      const SearchListCouponExposelimit = SearchListCoupon?.p?.exposelimit || 1
      /**
       * 判断曝光次数的key
       * @readonly
       * @enum {string}
       */
      const SHOW_CACHE_KEY_ENUM = {
        selectListDisplayedCoupon: `selectListDisplayedCoupon_${member_id}`,
        searchListCoupon: 'searchCouponLastShowTime'
      }

      if (isSelectPage) {
        // 选品页需要一张一张券判断，24小时内展示过就剃掉
        const cateCouponNot24Displayed = filterCouponWithin24Hours(SHOW_CACHE_KEY_ENUM.selectListDisplayedCoupon, cateCoupon, 1)?.slice(0, 3)
        
        // 选品的cateid是否24小时内在搜索对应的cateid显示过
        const cat_id = this.cat_info?.cat_id
        if (cat_id && checkCateCouponWithin24Hours(cat_id)) {
          return result
        }

        if (cateCouponNot24Displayed?.length) {
          result.cateCoupon = cateCouponNot24Displayed
          result.couponList = cateCouponNot24Displayed
        }

        result.receivedMode = RECEIVED_MODE_ENUM.login
      } else if (isSearchPage) {
        const cateCouponNot24Displayed = filterCouponWithin24Hours(SHOW_CACHE_KEY_ENUM.searchListCoupon, cateCoupon, SearchListCouponExposelimit || 1)
        result.cateCoupon = couponSlice(cateCouponNot24Displayed)
        if (isLogin) {
          if (result.showType === 'Flow') {
            result.receivedMode = RECEIVED_MODE_ENUM.click
          }
          // 对于同一个用户，根据SearchListCoupon来决定最大显示次数
          if (!(SearchSceneCoupon?.p?.SearchSceneCoupon) || !cateCouponNot24Displayed?.length) {
            return false
          }
          result.couponList = couponSlice(cateCouponNot24Displayed)
        } else if (!isLogin) {
          result.receivedMode = RECEIVED_MODE_ENUM.login
          const packageCouponCode = packageCoupon.map(item => item.couponCode)
          const packageCouponNot24Displayed = filterCouponWithin24Hours(SHOW_CACHE_KEY_ENUM.searchListCoupon, packageCouponCode, SearchListCouponExposelimit || 1)
          result.packageCoupon = packageCoupon.filter(item => packageCouponNot24Displayed.includes(item.couponCode))
          result.couponList = [...result.cateCoupon, ...result.packageCoupon]
        }
      }

      if (result.couponList.length > 0) {
        result.couponList = result.couponList.map((item) => {
          const itemTrans = Object.assign(item, couponSouceFormat(item)) 
          itemTrans.coupon_rule = itemTrans.coupon_rule?.splice(0, 3) ?? []
          return itemTrans
        })
      }
      
      if (result.couponList?.length > 0) {
        this.ListCouponContext = result
      }
    },
    loadBuyBoxDrawerInstance () {
      const callback = async () => {
        const results = await import('public/src/pages/common/BuyBoxPopup/index.js')
        this.buyBoxDrawerInstance = results.default
      }
      if (window.requestIdleCallback) {
        window.requestIdleCallback(callback)
      } else {
        const timer = setTimeout(() => {
          callback()
          clearTimeout(timer)
        }, 300)
      }
    },
    openBuyBoxDrawer({ cat_id, goods_id }) {
      // 清除上一个商品的数据
      if (this.buyBoxGoodsId !== goods_id) this.buyBoxDrawerInstance?.refreshData?.()
      this.buyBoxGoodsId = goods_id
      const { pageName } = this.catInfo 
      const { searchgoodsCard, listgoodsCard } = this.listAbtResult
      const displayWindowAbtBranch = pageName === 'page_search' ? searchgoodsCard?.p?.searchGoods : listgoodsCard?.p?.listGoods

      this.buyBoxDrawerInstance?.open?.({
        isNewStyle: true,
        isPaidUser: this.sheinClubInfo.isPaid,
        requestParams: {
          goods_id,
          cat_id,
          externalFields: {
            salesLabel: 1, // 销量标签
            promoLabel: 1, // 促销标签
            quickShip: 1, // 快速发货
            serviceLabel: 1, // 服务类标签-【通用角标类型-优选卖家】
          },
          pageKey: PAGE_NAME_MAP_PAGE_KEY[pageName] || '',
          cccParams: {
            displayWindowAbtBranch // 开启新商卡橱窗ABT
          },
        },
        analysisConfig: {
          activityFrom: 'buy_box',
          style: 'popup',
          goodsId: goods_id,
        },
        callback: {
          handleOpenLogin(fetchAddLike) {
            SHEIN_LOGIN.show({
              cb: () => fetchAddLike(),
              from: 'goods_list',
            })
          },
        },
      },)
    },
    filterResultChange(params) {
      this.$emit('filterChange', params)
    },
    // 修改券数据
    changeCateCoupon(val) {
      this.ListCouponContext = val
    },
    // 收集端上监控指标
    reportMetricCount({ metricName, tags, message }) {
      SIMetric.metricCount({
        metric_name: metricName,  // 指标名称
        tags, // 指标维度
        message // 日志信息
      })
    },
  },
  emits: ['pageChange', 'loadMore', 'filterChange'],
}
</script>

<style lang="less">
.product-list-v2 {
  .product-list {
    --item-horizontal-gap: 0px;
    --item-vertical-gap: 0px;

    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 0;
    gap: var(--item-vertical-gap) var(--item-horizontal-gap);

    &__empty {
      padding: 150px 0;
      font-size: 14px;
      text-align: center;
      /* rw:begin */
      padding-top: 44px;
      img {
        padding-bottom: 20px;
      }
      p {
        line-height: 20px;
        /* rw:begin */
        padding-top: 12px;
      }
    }

    &__item {
      position: relative;
      padding: 0 10px 16px;
      @media (max-width: 768px) {
        width: calc(50% - (1 * 0.5 * var(--item-horizontal-gap)));
      }
      @media (min-width: 768px) {
        width: calc(33.33% - (2 * 0.34 * var(--item-horizontal-gap)));
      }
      @media (min-width: 1200px) {
        width: calc(25% - (3 * 0.25 * var(--item-horizontal-gap)));
      }
      @media (min-width: 1800px) {
        width: calc(20% - (4 * 0.2 * var(--item-horizontal-gap)));
      }
      @media (min-width: 2300px) {
        width: calc(16.66% - (5 * 0.17 * var(--item-horizontal-gap)));
      }
    }

    &__item-new {
      padding: 8px;
      .base-img__href {
        // 解决列表中，无名称时，商品图片拉伸。直接在 BaseImg 中修改会影响其他已有组件样式
        position: relative;
      }
    }

    .recommend-panel__wrap {
      /* stylelint-disable-next-line declaration-property-value-blacklist */
      z-index: 2;
    }
  }

  // 新商品项 gap 控制
  .product-list-new {
    --item-horizontal-gap: 4px;
    --item-vertical-gap: 12px;
  }
}
.load-more-loading {
  margin-top: 32px;
}
.sui-pagination {
  margin-top: 20px;
}
</style>
