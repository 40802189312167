import { union } from 'lodash'

// SiteUID type sDateMap
export const MESSAGE_DATE = {
  'A-1': ['ar', 'tw', 'jp', 'rwar'],
  'A-2': ['www', 'us', 'il', 'asia', 'rw', 'rwus'],
  'A-3': ['de', 'fr', 'es', 'it', 'ca', 'se', 'mx', 'th', 'eur', 'nl', 'au', 'ma', 'br', 'sg', 'nz', 'pl', 'vn', 'rwde', 'rwfr', 'rwes', 'rwit', 'rwca', 'rwmx', 'rwau'],
  'A-4': ['uk', 'ru', 'in', 'hk', 'za', 'cl', 'rwuk', 'rwin'],
}

// 站点映射
const siteMap = {
  www: ['www', 'rw'],
  us:	['us', 'rwus'],
  ar:	['ar', 'rwar'],
  de:	['de', 'rwde'],
  fr:	['fr', 'rwfr'],
  es:	['es', 'rwes'],
  it:	['it', 'rwit'],
  ru:	['ru'],
  uk:	['uk', 'rwuk'],
  au:	['au', 'rwau'],
  tw:	['tw'],
  in:	['in', 'rwin'],
  mx:	['mx', 'rwmx'],
  il:	['il'],
  th:	['th'],
  nl:	['nl'],
  hk:	['hk'],
  vn:	['vn'],
  se:	['se'],
  ca:	['ca', 'rwca'],
  cl:	['cl'],
  eur: ['eur'],
  ma:	['ma'],
  br:	['br'],
  sg:	['sg'],
  za:	['za'],
  nz:	['nz'],
  pl:	['pl'],
  asia:	['asia'],
  id:	['id'],
  ph:	['ph'],
  pt:	['pt'],
  jp:	['jp'],
  my:	['my'],
  ch:	['ch'],
  roe: ['roe'],
  euqs:	['euqs'],
}

const timeFormatMap = {
  'F-2': ['uk', 'ru', 'in', 'hk', 'za', 'cl', 'nl'],    // 'DD-MM-YYYY HH:mm'
  'F-3': ['se'],                                  // 'YYYY-MM-DD HH:mm'
  'F-4': ['de', 'fr', 'es', 'it', 'ca', 'mx', 'th', 'eur', 'au', 'ma', 'br', 'sg', 'nz', 'pl', 'vn', 'il'], // 'DD/MM/YYYY HH:mm'
  'F-5': ['www', 'us', 'asia'],                   // 'MM/DD/YYYY HH:mm' 默认站点
  'F-7': ['jp', 'ar', 'tw'],                      // 'YYYY/MM/DD HH:mm' 
}

const timeFormatMapNoHours = {
  'A-4': ['uk', 'ru', 'in', 'hk', 'za', 'cl', 'nl'],    // DD-MM-YYYY
  'A-5': ['se'],                                        // YYYY-MM-DD
  'A-3': ['de', 'fr', 'es', 'it', 'ca', 'mx', 'th', 'eur', 'au', 'ma', 'br', 'sg', 'nz', 'pl', 'vn', 'il'], // DD/MM/YYYY
  'A-2': ['www', 'us', 'asia'],                         // MM/DD/YYYY' 默认站点
  'A-6': ['jp', 'ar', 'tw'],                            // YYYY/MM/DD 
}

let _COUPON_TIME_FORMAT_BY_ABT = {}
let _COUPON_TIME_FORMAT_NO_HOURS_BY_ABT = {}

for (const key in timeFormatMap) {
  if (Object.hasOwnProperty.call(timeFormatMap, key)) {
    const siteKeys = timeFormatMap[key]
    for (let index = 0; index < siteKeys.length; index++) {
      _COUPON_TIME_FORMAT_BY_ABT[key] = union((_COUPON_TIME_FORMAT_BY_ABT[key] || []).concat(siteMap[siteKeys[index]]))
    }
  }
}

for (const key in timeFormatMapNoHours) {
  if (Object.hasOwnProperty.call(timeFormatMapNoHours, key)) {
    const siteKeys = timeFormatMapNoHours[key]
    for (let index = 0; index < siteKeys.length; index++) {
      _COUPON_TIME_FORMAT_NO_HOURS_BY_ABT[key] = union((_COUPON_TIME_FORMAT_NO_HOURS_BY_ABT[key] || []).concat(siteMap[siteKeys[index]]))
    }
  }
}

export const COUPON_TIME_FORMAT_BY_ABT = Object.freeze(_COUPON_TIME_FORMAT_BY_ABT) // 携带时分
export const COUPON_TIME_FORMAT_NO_HOURS_BY_ABT = Object.freeze(_COUPON_TIME_FORMAT_NO_HOURS_BY_ABT) // 不携带时分

export const ORDER_DATE = {
  'A-1': ['ar', 'tw', 'jp', 'rwar'],
  'B-2': ['www', 'us', 'il', 'asia', 'rw', 'rwus'],
  'B-3': ['de', 'fr', 'es', 'it', 'ca', 'se', 'mx', 'th', 'eur', 'nl', 'au', 'ma', 'br', 'sg', 'nz', 'pl', 'vn', 'uk', 'ru', 'in', 'hk', 'za', 'cl', 'rwde', 'rwfr', 'rwes', 'rwit', 'rwca', 'rwmx', 'rwau', 'rwuk', 'rwin']
}

export const FULL_ORDER_DATE = {
  'E-7': ['ar', 'tw', 'jp', 'rwar'],
  'E-1': ['www', 'us', 'il', 'asia', 'rw', 'rwus'],
  'E-6': ['de', 'fr', 'es', 'it', 'ca', 'se', 'mx', 'th', 'eur', 'nl', 'au', 'ma', 'br', 'sg', 'nz', 'pl', 'vn', 'uk', 'ru', 'in', 'hk', 'za', 'cl', 'rwde', 'rwfr', 'rwes', 'rwit', 'rwca', 'rwmx', 'rwau', 'rwuk', 'rwin']
}

// 月日系列
export const SIMPLE_ORDER_DATE = {
  'D-1': ['ar', 'tw', 'jp', 'rwar'],
  'D-5': ['www', 'us', 'il', 'asia', 'rw', 'rwus'],
  'D-6': ['de', 'fr', 'es', 'it', 'ca', 'se', 'mx', 'th', 'eur', 'nl', 'au', 'ma', 'br', 'sg', 'nz', 'pl', 'vn', 'uk', 'ru', 'in', 'hk', 'za', 'cl', 'rwde', 'rwfr', 'rwes', 'rwit', 'rwca', 'rwmx', 'rwau', 'rwuk', 'rwin']
}

// lang type sDateMap for timeFormater
export const T_DATE = {
  'A-2': ['ph'],
  'A-3': ['hk'],
  'A-5': ['tw', 'ar'],
  'B-2': ['en', 'us', 'il', 'asia']
}
export const T_SHORT_DATE = {
  'D-2': ['hk'],
  'D-4': ['tw', 'ar'],
  'D-5': ['en', 'us', 'il']
}
export const T_DATE_TIME = {
  'E-5': ['ph'],
  'E-4': ['hk'],
  'E-3': ['tw', 'ar'],
  'E-1': ['en', 'us', 'il', 'asia']
}
export const T_SHORT_DATE_TIME = {
  'F-5': ['ph'],
  'F-4': ['hk'],
  'F-3': ['tw', 'ar'],
  'F-1': ['en', 'us', 'il', 'asia']
}
