/* 主要通过`firstStrategyGoodsNum`标识是否进行预请求 */

import { parseQueryString } from '@shein/common-function'
import { fetchProductListApi } from './index'
import { isStorePage } from '../store'

let promise = null

/**
 * @description: 触发预请求列表数据
 */
export const triggerPreFetchProductList = () => {
  promise = promise || preFetchProductList()
}

/**
 * @description: 获取预请求列表数据
 * @returns {Promise}
 * */
export const getPreFetchProductList = () => {
  if (!promise) {
    triggerPreFetchProductList()
  }
  return promise
}

/**
 * @description: 预请求列表数据
 * @param 参数从gbRawData上获取
 * */
async function preFetchProductList() {
  if (typeof window === 'undefined') return Promise.resolve()
  if (typeof gbRawData === 'undefined') return Promise.resolve()
  if (!gbRawData?.results?.firstStrategyGoodsNum || gbRawData?.results?.firstStrategyGoodsNum <= 0) return Promise.resolve() // 未配置首屏商品数量
  if (isStorePage()) return Promise.resolve() // 店铺页不预请求

  // 禁止浏览器滚动上一次的位置
  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual'
  }

  const atomicParams = gbRawData.results?.atomicParams || {}
  const catInfo = gbRawData.results?.cat_info || {}
  const searchKeywords = gbRawData.results?.searchKeywords || {}
  const request = {
    type: 'firstLoad',
    query: {
      page: 1,
      prePureGoods: 1, // 只请求商品数据
      ...parseQueryString(location.search)
    }
  }

  const data = await fetchProductListApi(
    { catInfo, searchKeywords, atomicParams, viewedGoods: [], request },
    { isPreFetch: true }
  ).catch(() => ({}))
  return data
}
