<template>
  <div
    class="filter-result"
  >
    <div
      class="icon"
    >
      <sui_icon_empty_75px_1
        size="80px"
      />
    </div>

    <div class="tips">
      {{ language.SHEIN_KEY_PC_32902 || 'Fewer products match the filter. You can modify the filter or' }}
    </div>

    <div
      class="list"
      :class="!selectedFilter.length ? 'list__empty' : ''"
    >
      <div
        v-for="(item,index) in selectedFilter"
        :key="index"
        :class="`item ${item.img ? 'item-color' : ''}`"
        @click="onClose(item)"
      >
        <img
          v-if="item.img"
          :src="item.img"
        />
        <span>{{ item.label }}</span>
        <sui_icon_closed_14px_1
          color="#000000"
          size="16px"
        />
      </div>
    </div>

    <div class="btn">
      <SButton
        :type="['H36PX', 'primary']"
        :disabled="loading"
        @click="clearAll"
      >
        {{ `${language.SHEIN_KEY_PC_15807 || 'Clear All'}` }}
      </SButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { sui_icon_closed_14px_1, sui_icon_empty_75px_1 } from '@shein-aidc/icon-vue3'
import { SButton } from '@shein-aidc/sui-button'
import mixin from './mixins'

export default {
  name: 'FilterResult',
  emits: ['clearAll', 'change'],
  components: {
    SButton,
    sui_icon_empty_75px_1,
    sui_icon_closed_14px_1,
  },
  mixins: [mixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState([
      'Results',
      'language'
    ]),
  },
  methods: {
    clearAll() {
      if (this.loading) return
      this.resetAllFilters()
    },
    onClose(item) {
      if (this.loading) return
      this.delSelectedFilter(item, { delFrom: 'filterWords' })
    }
  }
}
</script>

<style lang="less" scoped>
.filter-result{
  background: #fff;
  padding: 12px;
  text-align: center;

  .icon {
    margin-bottom: 24px;

    i{
      font-size: 60px;
      line-height: 60px;
    }
  }

  .tips {
    font-family: SF UI Text;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    letter-spacing: 0.0023999998811632395px;
    text-align: center;
  }

  .btn {
    div{
      display: inline-block;
      padding: 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.71px;
      text-align: center;
      color: #2D68A8;
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-height: 48px * 3 - 2;
    overflow: hidden;
    margin-bottom: 12px;
    margin-top: 12px;

    .item {
      height: 36px;
      line-height: 36px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(34, 34, 34, 1);
      padding: 6px 6px 6px 12px;
      min-width: 48px;
      margin: 0 5px 12px 5px; 
    }
    .item-color {
      padding-left: 6px;
    }

    .item img{
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 1px solid rgba(229, 229, 229, 1);
    }

    .item span {
      margin-right: 4px;
    }

    .item-color span {
      margin-left: 4px;
      font-size: 12px;
      color: #000;
    }

    .sui-icon-common__wrap{
      color: #000;
      font-size: 16px;
    }
  }

  .list__empty {
    margin-bottom: 24px;
  }
}
</style>
