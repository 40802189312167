<template>
  <div
    ref="cccFlowContainer"
    class="product-flow j-da-event-box"
  >
    <a
      class="product-flow__ad j-analysis-link"
      :href="
        cccPublic.getCccLink &&
          cccPublic.getCccLink({
            item: itemsData,
            cccCateInfo,
            gaIndex: position,
            scIndex: position,
            useDefaultTabIndex: false,
          })
      "
      :style="{
        paddingBottom: getPlaceholderHeight(itemsData.width, itemsData.height),
      }"
      :tabindex="adaLevel"
      :aria-label="itemsData.hrefTarget"
      da-event-expose="2-10-2"
    >
      <img
        :data-src="transformImg({ img: itemsData.imgSrc })"
        :src="LAZY_IMG_SOLID_COLOR"
        class="lazyload"
      />
    </a>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import { CccInit } from 'public/src/pages/common/ccc_public_v2'
import { getPlaceholderHeight } from '../utils'

import { CccAnalysis } from 'public/src/pages/common/analysis/auto_ccc_analysis.js'

export default {
  name: 'FlowAd',
  props: {
    // index: {
    //   type: Number,
    //   default: 0
    // },
    propData: {
      type: Object,
      default: () => ({}),
    },
    cccCateInfo: {
      type: Object,
      default: () => ({}),
    },
    context: {
      type: Object,
      default: () => ({}),
    },
    adaLevel: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      cccPublic: {},
    }
  },
  inject: ['transformImg'],
  computed: {
    ...mapState(['LAZY_IMG_SOLID_COLOR', 'tagId']),
    ...mapGetters(['listAbtResult']),
    itemsData() {
      return this.propData.content?.props?.items[0] || {}
    },
    position() {
      return Number(this.itemsData.position || 1) - 1
    },
  },
  mounted() {
    this.initCccPublic()
    this.handleFlowAdExpose()
  },
  methods: {
    getPlaceholderHeight,
    initCccPublic() {
      // CCC 跳转初始化
      const { Aod } = this.listAbtResult
      this.cccPublic = new CccInit({
        context: this.context,
        propData: this.propData,
        index: 1,
        sceneName: this.context.sceneName,
        tagId: this.tagId,
        abtGroups: [Aod],
      })
    },
    /**
     * 广告流banner埋点设置与曝光
     */
    handleFlowAdExpose() {
      this.$nextTick(() => {
        const bannerPoskey = []
        bannerPoskey.push(this.listAbtResult.Aod?.poskey?.slice(3) || '')
        // 1. 设置banner属性
        const cccAnalysis = new CccAnalysis(this.$refs['cccFlowContainer'], {
          index: 1,
          poskey: bannerPoskey.join(),
          saName: this.cccPublic.saName,
          scName: this.cccPublic.scName,
        })
        cccAnalysis.setDomsAttribute({
          propData: this.propData,
          useDefaultTabIndex: false,
          sceneName: this.context.sceneName,
        })
      })
    },
  },
}
</script>

<style lang="less">
.product-list-v2 {
  .product-flow__ad {
    display: inline-block;
    width: 100%;
    height: 0;
    background-color: #f7f7f7;
    img {
      width: 100%;
    }
  }
}
</style>
