
import { getPreFetchProductList, triggerPreFetchProductList } from '../productList'
import { getPreFetchStoreProductList, triggerPreFetchStoreProductList, isStorePage } from '../store'
import { triggerPreFetchThriftyFind, isThriftyFindPage } from '../thrifty_find'

import { triggerPreFetchDetailRealTimeData } from '../detail'
import './csrf'

export const preFetchGoodsPromise = () => {
  if (typeof window === 'undefined') return Promise.resolve()
  return isStorePage() ? getPreFetchStoreProductList() : getPreFetchProductList()
}

export const triggerPreFetchProductListPromise = () => {
  if (isThriftyFindPage()) return triggerPreFetchThriftyFind()
  triggerPreFetchDetailRealTimeData()
  triggerPreFetchStoreProductList()
  triggerPreFetchProductList()
}
