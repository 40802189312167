import schttp from 'public/src/services/schttp'
import { markPoint } from 'public/src/services/mark'


let isRequestCompletedOnce = false

export const triggerPreFetchDetailRealTimeData = () => {
  window.__detailPromise__ = window.__detailPromise__ || preFetchDetailRealTimeData()
}

export const getPreFetchDetailRealTimeData = () => {
  if (!window.__detailPromise__) {
    triggerPreFetchDetailRealTimeData()
  }
  const pm = window.__detailPromise__
  return pm.then((res) => {
    if (isRequestCompletedOnce) {
      return undefined
    }
    if (res) {
      isRequestCompletedOnce = true
    }
    return res
  })
}

  
async function preFetchDetailRealTimeData () {
  if (typeof window === 'undefined') return Promise.resolve()
  const gbRawData = window.gbRawData || {}
  if (gbRawData.templateType !== 'goods_detail') return Promise.resolve()
  const { isDebug } = window.gbCommonInfo || {}
  const { mallCode, detail } = gbRawData.productIntroData
  const goods_id = detail?.goods_id
  markPoint({ eventName: 'realTime' })

  try {
    const res = await schttp({
      method: 'POST',
      url: '/api/productInfo/realTimeData/query',
      ignoreInterceptor: ['csrf'],
      ...(isDebug ? {} : { timeout: 4000 }),
      data: {
        goods_id,
        mallCode,
        specialSceneType: window.gbRawData.productIntroData?.closeEstimatedAndAbPrice ? 1 : 0,
        tags: window.gbRawData.productIntroData?.allTags || []
      }
    })
    
    if (!res || !res.data || res.code !== 0) {
      return
    }
    return res.data
  } catch (e) {
    console.error(e)
    window.__detailPromise__ = undefined
    return
  }
}
